import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-amount-field-editable',
  templateUrl: './amount-field-editable.component.html',
  styleUrls: ['./amount-field-editable.component.scss']
})
export class AmountFieldEditableComponent implements OnInit {
  @Input() data;
  @Input() parent = {};
  @Input() field;
  @Output() amountFieldChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.data, this.parent, this.field);
  }

  modify(data, parent, e) {
    e.stopPropagation();
    this.data[this.field.name] = Number(this.data[this.field.name]);
    this.amountFieldChange.emit({ data, parent, field: this.field.name });
  }
}
