import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable()
export class AppJsonDataService {
  private MEDIA_STORE_JSON_URL: string = '../assets/jsons/media-store.json?v=' + Math.random();
  public mediaStoreSubject: Subject<{}> = new Subject<{}>();

  constructor(private http: HttpClient) { }

  public getMediaStoreJson() {
    return this.http.get(this.MEDIA_STORE_JSON_URL);
  }

  get getMediaStoreSubject$(): Observable<any> {
    return this.mediaStoreSubject.asObservable();
  }
}
