import { Injectable, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CommonGridService {
parentfilterList=[];
filterList=[];
    public getFilterObject(fullObj, key) {
        const uniqChk = [];
        fullObj.filter((obj) => {
          if (!uniqChk.includes(obj[key])) {
            uniqChk.push(obj[key]);
          }
          return obj;
        });
        return uniqChk;
    }

    public getFirstLevelFilteredOptions(data, object) {
        object = this.getFilterOptions(data, object);
        return this.getFilteredValue(object);
    }

    public getNextLevelFilteredOptions(data, level, object) {
        data.filter(fData => {
            if (fData[level]) {
                object = this.getFilterOptions(fData[level], object);
            } else {
                fData.eSecondData.filter(sData => {
                    if (sData[level]) {
                        object = this.getFilterOptions(sData[level], object);
                    }
                });
            }
        });
        return this.getFilteredValue(object);
    }

    public orderByWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
        const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
        if (pAttr === 'name') {
        return _.concat(partition[1], _.orderBy(partition[0], (i) => typeof i.name === 'string' &&
                        i.name.toLowerCase(), (pReverse ? 'desc' : 'asc')));
        }
        else { return _.concat(partition[1], _.orderBy(partition[0], pAttr, (pReverse ? 'desc' : 'asc'))); }
    }

    public orderByDateWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
        const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
        return _.concat(partition[1], _.orderBy(partition[0], (dateObj) => { // function(dateObj) {
            return new Date(dateObj.name);
        }));
    }

    public setFilterData(dataSource, level, object, filterValues, selItem, filterLevels) {
        this.parentfilterList = [];
        this.filterList = [];
        const expandLevel = this.getExpandLevel(level);
        const firstLevelKey = this.findKeys(filterLevels, 'eFirstData');
        const secondLevelKey = this.findKeys(filterLevels, level);
        dataSource.data.filter(fData => {
            if (fData[expandLevel]) {
                this.getFilterValues(fData[expandLevel], object, filterValues, selItem);
                if (fData[expandLevel].dataSource.filteredData.length === 0) {
                    this.setParentFilter(dataSource, fData[firstLevelKey], firstLevelKey);
                }
            } else {
                fData.secondLevel.dataSource.data.filter(sData => {
                    if (sData[expandLevel]) {
                        this.getFilterValues(sData[expandLevel], object, filterValues, selItem);
                        if (sData[expandLevel].dataSource.filteredData.length === 0) {
                            this.setNextParentFilter(fData.secondLevel.dataSource, sData[secondLevelKey], dataSource,
                                                    fData[firstLevelKey], firstLevelKey, secondLevelKey);
                        }
                    }
                });
            }
        });
    }

    public findKeys(obj, value) {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    public wbsElementValidationCheck(data) {
        data.eSecondData.filter(level => {
            if (level.hasOwnProperty('wbsElementValidation')) {
                // level.wbsElementValidation = true;
            }
        });
        return data;
    }

    private setNextParentFilter(dataSource, removeName, pdataSource, premoveName, pkey, key) {
        // const filterValues = [];
        dataSource.data.filter(fData => {
            if (fData[key] !== removeName) {
              this.parentfilterList.push(fData[key]);//filterValues.push(fData[key]);
            }
        });
        dataSource.filter = JSON.stringify({show: this.parentfilterList});
        if (dataSource.filteredData.length === 0) {
            this.setParentFilter(pdataSource, premoveName, pkey);
        }
    }

    private setParentFilter(dataSource, removeName, key) {
        //const filterValues = [];
        dataSource.data.filter(fData => {
            if (fData[key] !== removeName) {
              this.filterList.push(fData[key]);
                //filterValues.push(fData[key]);
            }
        });
      let filterValue = {};
      filterValue[key] = this.filterList;
        dataSource.filter = JSON.stringify(filterValue);
    }

    private getExpandLevel(level) {
        if (level === 'eSecondData') {
            return 'secondLevel';
        } else if (level === 'eThirdData') {
            return 'thirdLevel';
        } else if (level === 'eFourthData') {
            return 'fourthLevel';
        }
    }

    private getFilterValues(data, object, filterValues, selItem) {
        if (data.dataSource.data.length > 0) {
            data.dataSource.filter = JSON.stringify(filterValues);
        }
    }

    public getFilterOptions(data, object) {
        this.getFilterObject(data, object.name).forEach(item => {
            if (item == null || item == undefined || item == '') {
                object.options.unshift({ name: item, checked: false });
            }
            else {
                const trimmedText = item.toString().trim();
              if(object.options.filter(row=>row.name== trimmedText).length<=0)
                object.options.push({ name: trimmedText, checked: false });
            }
        });
        return object;
    }

    public getFilteredValue(object) {
        object.options = [...new Map(object.options.map(item => [item.name, item])).values()];
        if (object.filterSort && object.filterSort.indexOf('date') !== -1) {
            object.options = this.orderByDateWithNullsAtStart(object.options, 'name', false);
        }
        else {
            object.options = this.orderByWithNullsAtStart(object.options, 'name', false);
        }
        object.filteredOptions = object.options.slice();
        return object;
    }
}
