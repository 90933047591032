import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuthManagerService } from 'src/app/services/auth/authManager.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  private userDataSub = new Subscription();
  public isR2 = false
  loadingIcon = false;
  navlinks2 = [
    {
      label: 'Estimates',
      link: '/estimates',
      index: 0
    },
    {
      label: 'Campaigns',
      link: '/campaigns',
      index: 1
    },
    {
      label: 'Invoices',
      link: '/invoices',
      index: 2
    },
    {
      label: 'Reports',
      link: '/reports',
      index: 3
    },
    {
      label: 'Accruals',
      link: '/accruals',
      index: 4
    },
    {
      label: 'Admin',
      link: '/admin',
      index: 5
    }
  ];
  navlinks = [];
  activeLinkIndex = -1; activeLink;
  isLoggedIn$: Observable<boolean>;
  isLoginValidation ="";
  user: string;
  links = ['Estimates', 'Campaigns', 'Invoices', 'Reports', 'Accruals', 'Admin'];
  logoRedirectionUrl:string;

  constructor(private router: Router, private authService: AuthService, private authManagerService: AuthManagerService,
              public commonService: CommonService) {
    this.loadingIcon = true;
    this.isR2= localStorage.getItem('releaseVersion') === 'R2';
    this.userDataSub = this.commonService.userData.subscribe((res: any) => {
      if (res['userDetails'] && res['userDetails']['user'] && res['userDetails']['user'].userName) {
        this.user = res['userDetails']['user'].userName;
        if (res.userTabs && res.userTabs.length > 0) {
          this.navlinks = this.navlinks2.filter(obj => res.userTabs.indexOf(obj.link.replace('/', '')) > -1);
        }
        if( res['defaultModuleName'] !== undefined && res['defaultModuleName'] )
          this.logoRedirectionUrl = '/'+res['defaultModuleName'].toLowerCase();

      }
      else { // if (res['error'] && res['error'].errorCode === "E0003") {
        this.user = '';
        // this.router.navigate(['./error']);
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authManagerService.isLoggedIn;
    this.isLoginValidation = this.authService.loggedInValidation;

    this.activeLinkIndex = 0;
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationStart){
        if (res.url === '/') { this.activeLink = this.navlinks[0]; }
        // this.activeLinkIndex = this.navlinks.indexOf(this.navlinks.find(tab => tab.link === '.' + this.router.url));
      }
      if (res instanceof NavigationEnd){
        this.loadingIcon = false;
      }
    });
  }

  logoutUser(){
    // if(environment.name!=='Local'){
    //   newrelic.addPageAction('Logout','');
    // }
    this.authService.logoutUser();
  }

  toggleRelease(value)
  {
    value === true? this.commonService.releaseVersionSubject.next('R2'): this.commonService.releaseVersionSubject.next('R1');
    window.location.reload();
    this.router.navigateByUrl('/campaigns');
    this.activeLink = this.navlinks[0];

  }

  ngOnDestroy() {
    this.userDataSub.unsubscribe();
  }

}
