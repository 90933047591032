export const CampaignsConstants = {
  apiUrl: {
    flightsSave: 'campaign/flights',
    getCampaigns: 'campaign/load',
    masterDataCampaigns: 'campaign/lov',
    saveAddIOs: 'campaign/save/io',
    saveNotes: 'campaign/comments/save',
    saveTotalCommitment: 'campaign/update/commitmentamount',
    getCampaignHistory: 'campaign/history/',
    getCampaignsIOs: 'campaign/load/details',
    issueSelectedIOs: 'campaign/io/issue',
    updateIO: 'campaign/update/io',
    export: 'aspose/exportIo',
    deleteIO: 'campaign/io/delete',
    deleteFlight:'campaign/flights/delete',
    importIO: 'aspose/importIO',
    importIOUpdate: 'aspose/bulkUpdate',
    downloadTemplate: 'aspose/downloadIOTemplate'
  },
  "campaignHistory" :{
    displayNames: ['Change Type', 'Field Updated', 'Action', 'Media Type', 'Media Sub-Type', 'Month/Year', 'Old Value', 'New Value', 'Variance', 'Updated By', 'Updated On']
  },

  "History_headers": [
    {
      "name": "EXPORT TABLE",
      "routerLink": "",
      "isEnabled": true,
      "iconPath": ""
    }
  ]
};
