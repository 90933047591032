import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-row-modify',
  templateUrl: './app-row-modify.component.html',
  styleUrls: ['./app-row-modify.component.scss']
})

export class AppRowModifyComponent implements OnInit {
  @Input() data;
  @Output() rowModify: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  rowModifyChange(data, event, action) {
    if (action === 'removeCopy' || action === 'edit' || this.checkValidation(data)) {
      if (action === 'edit') {
        // event.target.classList.add('hide');
        // rowEditMode.classList.add('show');
        data.editMode = true;
      } else {
        // rowEdit.classList.remove('hide');
        // rowEditMode.classList.remove('show');
        data.editMode = false;
      }
      this.rowModify.emit({ currentItem: data, operation: action });
    }
  }

  private checkValidation(data) {
    let returnVal = true;
    if (!this.validateDate(data.startDate, data.endDate) && data.mediaSubType) {
      if (data.eSecondData.length > 0) {
        for (const ioData of data.eSecondData) {
          if ((ioData.commitmentAmount !== null && this.validateDate(ioData.startDate, ioData.endDate))) {
            returnVal = false;
            break;
          }
        }
      } else {
        returnVal = false;
      }
    } else {
      returnVal = false;
    }
    return returnVal;
  }

  private validateDate(startDate, endDate) {
    const sDate = Date.parse(startDate);
    const eDate = Date.parse(endDate);
    return ((sDate > 0 && !isNaN(sDate)) && (eDate > 0 && !isNaN(eDate))) ? false : true;
  }
}
