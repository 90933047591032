import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DynamicEventQueueService, AppEvent, AppEventType } from '../../../services/dynamic-event-queue.service';
@Component({
  selector: 'app-dynamic',
  templateUrl: './app-dynamic.component.html',
  styleUrls: ['./app-dynamic.component.scss']
})
export class AppDynamicComponent implements OnInit {
  @Input() data;
  @Input() parent = {};
  @Input() field;
  @Input() selectOptions = {};
  @Output() dynamicCompChange: EventEmitter<any> = new EventEmitter();
  @Output() dynamicCompInputFieldBlur: EventEmitter<any> = new EventEmitter();
  showNames = [];
  mediaTypeMaster = [];
  mediaTypes = [];
  mediaSubTypes = [];
  selectedShowName;
  selectedMediaType;
  selectedMediaSubType;
  dateFormatted;
  validationFlag = false;
  constructor(private eventQueue: DynamicEventQueueService) { }

  ngOnInit(): void {
    this.eventQueue.on(AppEventType.MediaTypeClickedOnNotification).subscribe(event => this.handleEvent(event.payload));
    // console.log(this.data, this.field, this.field.type);
    if (this.field.type === 'date') {
      this.data[this.field.name] = new Date(this.data[this.field.name]);
    } else if (this.field.type === 'select') {
      if (this.field.name === 'showName') {
        this.showNames = this.selectOptions['shows'];
        this.selectedShowName = this.data[this.field.name];
      } else if (this.field.name === 'mediaType') {
        this.mediaTypeMaster = this.selectOptions['mediaType'];
        this.mediaTypes = this.mediaTypeMaster.map(a => a.mediaTypeName);
        this.selectedMediaType = this.data[this.field.name]; console.log(35, this.selectedMediaType);
        this.selectedMediaSubType = this.data['mediaSubType'];
      } else if (this.field.name === 'mediaSubType') {
        this.mediaSubTypes = this.getMediaSubTypes(this.data['mediaType']);
        // console.log(39, this.mediaSubTypes);
        this.selectedMediaSubType = this.data[this.field.name];
      }
    }
  }

  getMediaSubTypes(mediaType) {
    // console.log(mediaType);
    return this.selectOptions['mediaType'].find(a => a.mediaTypeName === mediaType).mediaSubTypes.map(b => b.mediaSubTypeName)
  }

  amountFieldChangeEvent($event) {
    this.dynamicCompChange.emit($event);
  }

  modify(data, parent, e) {
    this.validationFlag = false;
    if (this.field.type === 'date') {
      const dateCheck = Date.parse(data[this.field.name]);
      if (isNaN(dateCheck)) {
        this.validationFlag = true;
      }
    }
    if (!this.validationFlag) {
      this.dynamicCompChange.emit({ data, parent, field: this.field.name });
    }
  }

  onSelectionChange(data, parent, e) {
    data[this.field.name] = e.value;
    this.dynamicCompChange.emit({ data, parent, field: this.field.name });
    if (this.field.name === 'mediaType') {
      this.selectedMediaSubType = '';
      data.mediaSubType = '';
      data.mediaSubTypeId = null;
      this.eventQueue.dispatch(new AppEvent(AppEventType.MediaTypeClickedOnNotification, data));
    }
  }
  onBlur(data, parent) {
    // console.log(79, data, parent);
    this.dynamicCompInputFieldBlur.emit({ data, parent, field: this.field.name });
  }

  handleEvent(data) {
    if (this.field.name === 'mediaSubType' && data.ioId === this.data.ioId) {
      this.mediaSubTypes = this.getMediaSubTypes(data['mediaType']);
      this.selectedMediaSubType = '';
    }
  }
}
