import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { HttpClientModule } from '@angular/common/http';

import { ModuleRouting } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/** Services */
import { AppJsonDataService } from './services/app-json.service';
import { AppSingletonService } from './services/app-singleton.service';
import { CommonService } from './services/common.service';
import { AppUtilityService } from './services/utility.service';
import { AuthService } from './services/auth/auth.service';
import { AuthManagerService } from './services/auth/authManager.service';
import { DynamicEventQueueService } from './services/dynamic-event-queue.service';
import { NgIdleModule } from '@ng-idle/core';
import { LoginErrorComponent } from './media-management/login-error/login-error.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginErrorComponent
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot(),
    ModuleRouting,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgIdleModule.forRoot()
  ],
  providers: [AuthManagerService, AuthService, AppJsonDataService, AppSingletonService,
  CommonService, AppUtilityService, DynamicEventQueueService],
  bootstrap: [AppComponent]
})
export class AppModule { }
