import { Injectable } from '@angular/core';

@Injectable()
export class AppSingletonService {
    public mediaJsonStore: any;
    public mediaJsonStoreCheck = false;
    public adminJsonStore: any;
    public adminJsonStoreCheck = false;
    constructor() {
        /** Nothing to do */
    }
}
