import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-estimate',
  templateUrl: './app-edit-estimate.component.html',
  styleUrls: ['./app-edit-estimate.component.scss']
})
export class AppEditEstimateComponent implements OnInit {
  @Input() current;
  @Input() parent;
  @Input() levelIndex;
  @Input() header;
  @Input() showTooltip;
  @Output() hidenResetFilter: EventEmitter<boolean> = new EventEmitter();
  public amountHeaders = ['estimate'];

  @Output() public emitEditted: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.header && this.header.name === 'serviceMonth') {
      this.showTooltip  = false;
    }
  }

  estimatesEditableMode(e, estimatesDisplayFieldWrapper, estimatesEditable) {
    e.preventDefault();
    e.stopPropagation();
    this.hidenResetFilter.emit(true);
    estimatesDisplayFieldWrapper.classList.add('hide');
    estimatesEditable.classList.add('show');
    estimatesEditable.children[0].focus();
  }

  modifyEstimates(current, parent, level, action, estimatesDisplayFieldWrapper, estimatesEditable, event) {
    if (action !== 'M'){
      event.preventDefault();
      event.stopPropagation();
    }
    this.emitEditted.emit({ currentItem: current, parentItem: parent, levelIndex: level, operation: action });
    if ((action === 'F') || action === 'S' || action === 'R') {
      estimatesDisplayFieldWrapper.classList.remove('hide');
      estimatesEditable.classList.remove('show');
    }
  }

  isAmount(headerName, data) {
    if (this.amountHeaders.length > 0 && this.amountHeaders.indexOf(headerName) > -1 && (data || data === 0)) {
      return true;
    }
    else {
      return false;
    }
  }
}
