<ng-container *ngIf="data.validations.length; else fileIcon">
    <span class="warning">
        <mat-icon [ngClass]="{'soft-warning': warningType === 'S', 'hard-warning': warningType === 'H' }"
            [tooltip]="getValidationErrorTexts(data.validations)" placement="bottom" show-delay="100" hide-delay="100">
            warning
        </mat-icon>
    </span>
</ng-container>
<ng-template #fileIcon>
    <ng-container *ngIf="(data.showTree?.invoiceId || !data.showTree?.ioId) && showAttachment">
        <mat-icon class="attach-icon">attach_file</mat-icon>
    </ng-container>
</ng-template>
