import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-notes',
  templateUrl: './app-notes.component.html',
  styleUrls: ['./app-notes.component.scss']
})
export class AppNotesComponent implements OnInit {
  @Input() data;
  @Input() header;
  @Input() inlineSave: boolean = false;
  @Input() rowEdit: boolean = false;
  @Output() hidenResetFilter: EventEmitter<boolean> = new EventEmitter();
  @Output() notesChange: EventEmitter<any> = new EventEmitter();
  defaultEditableHeight;
  editIconHeight;

  constructor() { }

  ngOnInit(): void {
    this.defaultEditableHeight = this.inlineSave ? 24 : 43;
    this.editIconHeight = this.inlineSave ? 24 : 5;
  }

  focusNotesField(e) {
    e.stopImmediatePropagation();
    e.stopPropagation();
  }

  notesEditableMode(e, notesDisplayFieldWrapper, notesEditable) {
    e.stopPropagation();
    // this.hidenResetFilter.emit(true);
    notesDisplayFieldWrapper.classList.add('hide');
    notesEditable.classList.add('show');
    notesEditable.focus();
  }

  modifyNotes(data, action, notesDisplayFieldWrapper, notesEditable, e = '') {  /* get notes partialData prepared for save during modelChange record */
    this.notesChange.emit({ currentItem: data, comment: e, operation: action });
    if (action === 'removeCopy' || action === 'save') {
      notesDisplayFieldWrapper.classList.remove('hide');
      notesEditable.classList.remove('show');
    }
  }
}
