import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Inject,
  LOCALE_ID,
  ElementRef
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { IEstimatesDetail, IEstimatesList, IUpdateEstimates } from '../../../models/estimates.model';
import { Action } from 'rxjs/internal/scheduler/Action';
import * as _ from 'lodash';
import { CommonGridService } from './common-grid.service';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { faCommentAlt as fasCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
@Component({
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandThird', [
      state('closed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('open', style({ height: '*', visibility: 'visible' })),
      transition('closed <=> open', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  selector: 'app-common-grid',
  templateUrl: './common-grid.component.html',
  styleUrls: ['./common-grid.component.scss']
})
export class CommonGridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public gridData;
  @Input() public isMultiRowHeader = false;
  @Input() public emitFilterData = false;
  @Input() public selectAllFilteredData = false;
  @Input() public showFooter = false;
  @Input() public numberFormat = '1.0-0';
  @Input() public gridName ="";
  dataSourceDataProperty = 'data';
  @ViewChild('firstData', { read: MatSort, static: true }) firstData: MatSort;
  @ViewChild('secondData', { read: MatSort, static: true }) secondData: MatSort;
  @ViewChild('thirdData', { read: MatSort, static: true }) thirdData: MatSort;
  @ViewChild('fourthData', { read: MatSort, static: true }) fourthData: MatSort;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() public emitFilteredData: EventEmitter<any> = new EventEmitter();
  @Output() public emitEdittedEstimates: EventEmitter<any> = new EventEmitter();
  @Output() public emitNotesEditted: EventEmitter<any> = new EventEmitter();
  @Output() public emitRowModify: EventEmitter<any> = new EventEmitter();
  @Output() public emitAddRow: EventEmitter<any> = new EventEmitter();
  @Output() public emitSelectedRows: EventEmitter<any> = new EventEmitter();
  @Output() public emitEdittedDynamicCompField: EventEmitter<any> = new EventEmitter();
  @Output() public emitDynamicCompInputFieldBlur: EventEmitter<any> = new EventEmitter();
  @Output() public emitClickedData: EventEmitter<any> = new EventEmitter();
  @Output() public emitCurrentDataRow: EventEmitter<any> = new EventEmitter();
  @Output() public emitShowFilter: EventEmitter<boolean> = new EventEmitter();
  @Output() public emitMenuItem: EventEmitter<any> = new EventEmitter();
  @Output() public cgToggleData: EventEmitter<any> = new EventEmitter();
  @Output() public emitDeleteRow: EventEmitter<any> = new EventEmitter();
  canAllow = true;
  amountHeaders = [];
  public showNoMsg = false;
  filterEnabled = false;
  filterValues = {};
  filterSelectObj = [];
  menuItems =  [];
  selectedMenuRow;
  public dataSourceFirstLevelList = {
    dataSource: new MatTableDataSource<any>(),
    selection: new SelectionModel<any>(true, [])
  };
  displayedColumns: string[] = [];
  displayedColumns2: string[] = [];
  displayNewTableLevel2 = false;
  defaultSort = '';
  defaultSortDirection = 'asc';
  notesUpdatedValues = [];
  filterAnchor = '';
  enableFilter = false;
  level: any;
  filterLevels = ['secondLevel', 'thirdLevel', 'fourthLevel'];
  dateRangeFilterText = '';
  displayPageSize = 10;
  displayPageSizeOptions = [5, 10, 25, 100];
  //font awesome icons for notes
  faCommentAlt = faCommentAlt;
  fasCommentAlt = fasCommentAlt;
  loadingIcon = false;

  constructor(private cGS: CommonGridService, public dialog: MatDialog,@Inject(LOCALE_ID) private locale: string, private cS: CommonService) { }

  ngOnInit(): void {
    // console.log(this.gridData);
    this.dataSourceDataProperty = this.selectAllFilteredData ? 'filteredData': 'data';
    this.filterSelectObj = this.gridData.columns;
    this.displayedColumns = this.gridData.displayColumns;
    this.displayNewTableLevel2 = this.gridData.displayNewTableLevel2 ? true : false;
    this.displayedColumns2 = this.gridData.displayColumns2;
    this.showNoMsg = this.gridData.data.length === 0 ? true : false;
    this.menuItems = this.gridData.menuItems ? this.gridData.menuItems : [] ;
    this.amountHeaders = this.gridData.amountColumns ? this.gridData.amountColumns : [];
    this.defaultSort = this.gridData.defaultSort ? this.gridData.defaultSort.column : this.defaultSort;
    this.defaultSortDirection = this.gridData.defaultSort ? this.gridData.defaultSort.direction : this.defaultSortDirection;
    if (this.gridData.pagination) {
      this.dataSourceFirstLevelList.dataSource = new MatTableDataSource();
    }
    else{
      this.dataSourceFirstLevelList.dataSource = new MatTableDataSource(this.gridData.data);
    }
    this.dataSourceFirstLevelList.selection = new SelectionModel<any>(true, []);
    this.dataSourceFirstLevelList.dataSource.filterPredicate = this.createFilter();
    this.updateSelectionModel(null);
    if (this.gridData.checkAll) {
      this.masterToggle();
    }
    if(this.gridData.rowModify.checkbox && (this.gridData.page && this.gridData.page === 'closeAccruals')){
      this.gridData.data.forEach(data => {
        if(data.isChecked) {
          this.dataSourceFirstLevelList.dataSource.data.forEach((row) => {
              if(row.isChecked) {this.dataSourceFirstLevelList.selection.select(row);}
          });
          this.emitRowsSelected({ checked: true, data: [data], selectedList: this.dataSourceFirstLevelList.selection });
        }
      });
    }
    if (this.gridData.pagination) {
      this.dataSourceFirstLevelList.dataSource.paginator = this.paginator;
    }
  }

  // rowClick(data) {
  //   if (data.secondLevel) {
  //     data.secondLevel.displayResults = false;
  //   }
  // }

  ngAfterViewInit() {
    if(this.gridName==="invoiceGrid" && this.gridData.data.length > 0){
      this.sort.sort(({ id: 'showName', start: 'asc'}) as MatSortable);
    }
    // setTimeout(() => {
    this.dataSourceFirstLevelList.dataSource.sort = this.firstData;
    // issue fix, part of MAFN-184
    this.dataSourceFirstLevelList.dataSource.sortingDataAccessor = this.sortDataAccessorFn;
    if (this.gridData.pagination && this.dataSourceFirstLevelList.dataSource.paginator) {
      this.dataSourceFirstLevelList.dataSource.data = this.gridData.data;
    }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gridData) {
      this.hideFilter(true);
      this.showNoMsg = this.gridData.data.length === 0 ? true : false;
      if (this.gridData.pagination && this.dataSourceFirstLevelList.dataSource.paginator) {
        //Retain selected rows after grid reload  - This feature required for  only for campaignGrid
           if(this.gridName ==="campaignGrid" && this.dataSourceFirstLevelList.selection['_selected'] && this.dataSourceFirstLevelList.selection['_selected'].length > 0){
              var preSelected = this.dataSourceFirstLevelList.selection['_selected'];
              this.dataSourceFirstLevelList.selection.clear();
              this.gridData.data.forEach(uData =>
                {
                    preSelected.filter( sData => {
                    if (uData.showId === sData.showId) {
                      this.dataSourceFirstLevelList.selection.select(uData);
                   }
                }
              );
            }
          );
        }
        //For Server side updates with client side pagination that requires re-paint
        this.dataSourceFirstLevelList.dataSource.data =  this.gridData.data;
        this.dataSourceFirstLevelList.dataSource.paginator = this.paginator;
     } else {
      // For grid's without pagination reassign the first level datasource (previous flow)
        this.dataSourceFirstLevelList.dataSource = new MatTableDataSource(this.gridData.data);
      }
      this.dataSourceFirstLevelList.dataSource.sort = this.firstData;
      // issue fix, part of MAFN-184
      this.dataSourceFirstLevelList.dataSource.sortingDataAccessor = this.sortDataAccessorFn;
      this.dataSourceFirstLevelList.dataSource.filterPredicate = this.createFilter();
      this.updateSelectionModel(changes.gridData.currentValue.data);
      if (this.gridData.data && this.gridData.data.length > 0){
        this.filterSelectObj = this.gridData.columns;
        // this.filterBtnClk(false);
      }
      if(this.gridName==="invoiceGrid" && this.gridData.data.length > 0){
      this.dataSourceFirstLevelList.dataSource.data.forEach(data => {
        if(data.secondLevel && this.cS.showIDList.indexOf(data.showId) > -1) {
          data.secondLevel.displayResults = true;
        }
      });
      this.cS.showIDList=[];
    }
    }
  }

  // issue fix, part of MAFN-184
  sortDataAccessorFn(item, property){
    if (property && item[property] && property.toLowerCase().indexOf('date') > -1) {
      return new Date(item[property]);
    }
    else if (property && item[property] && typeof item[property] === 'string'){
      return item[property].toLowerCase();
    }
    else {
      return item[property];
    }
  }

  sortChangesSecondLevel(sort, data) {
    if (this.displayNewTableLevel2) {
      for (const firstLevel of this.dataSourceFirstLevelList.dataSource.data) {
        if (firstLevel.ioId === data.ioId) {
          const dataLevel = [...firstLevel.secondLevel.dataSource.data];
          firstLevel.secondLevel.dataSource.data = [...this.sortingInternal(dataLevel, sort)];
        }
      }
    }
  }

  sortChanges(sort) {
    for (const firstLevel of this.dataSourceFirstLevelList.dataSource.data) {
      if (firstLevel.secondLevel && firstLevel.secondLevel.dataSource.data.length > 0) {
        const dataLevel = [...firstLevel.secondLevel.dataSource.data];
        firstLevel.secondLevel.dataSource.data = [...this.sortingInternal(dataLevel, sort)];
        for (const secondLevel of firstLevel.secondLevel.dataSource.data) {
          if (secondLevel.thirdLevel && secondLevel.thirdLevel.dataSource.data.length > 0) {
            const dataSLevel = [...secondLevel.thirdLevel.dataSource.data];
            secondLevel.thirdLevel.dataSource.data = [...this.sortingInternal(dataSLevel, sort)];
          }
        }
      } else if (firstLevel.eSecondData && firstLevel.eSecondData.length > 0){
        const dataLevel = [...firstLevel.eSecondData];
        firstLevel.eSecondData = [...this.sortingInternal(dataLevel, sort)];
        for (const secondLevel of firstLevel.eSecondData) {
          if (secondLevel.eThirdData && secondLevel.eThirdData.length > 0) {
            const dataSLevel = [...secondLevel.eThirdData];
            secondLevel.eThirdData = [...this.sortingInternal(dataSLevel, sort)];
          }
        }
      }
    }
    if (sort.active === 'lastUpdatedBy') {
      this.sortingInternal(this.dataSourceFirstLevelList.dataSource.data, sort);
    }
    // Added for accrual grids (for firstlevel sort data should be emitted)
    if (this.gridData.emitSortData) {
      const sortedData = this.dataSourceFirstLevelList.dataSource.sortData(this.dataSourceFirstLevelList.dataSource.data, this.dataSourceFirstLevelList.dataSource.sort);
      this.emitFilteredData.emit(
        {
          data: sortedData,
          column: null,
          clickedValue: null,
          checked: false
        });
    }
  }

  sortingInternal(data, sort) {
    data.sort((a, b) => {
      if (!sort.active || (a[sort.active] === '' && b[sort.active] === '')) {
        return 0;
      }
      const isAsc = sort.direction === 'asc';
      // updated to work for any column, fix added as a part of MAFN-48
      if ((a.isOmitSort && b.isOmitSort) || a.isOmitSort){
        return 1;
      }
      else if (b.isOmitSort){
        return -1;
      }
      else{
        const aTemp = a[sort.active] && typeof a[sort.active] === 'string' ? a[sort.active].toLowerCase() : a[sort.active];
        const bTemp = b[sort.active] && typeof b[sort.active] === 'string' ? b[sort.active].toLowerCase() : b[sort.active];
        return this.compare(aTemp, bTemp, isAsc);
      }
    });
    return data;
  }

  isAllSelected() {
    const numSelected = this.dataSourceFirstLevelList.selection.selected.length;
    const numRows = this.dataSourceFirstLevelList.dataSource[this.dataSourceDataProperty].length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const isSelected = this.isAllSelected();
    if (isSelected) {
      this.dataSourceFirstLevelList.selection.clear();
    }
    this.dataSourceFirstLevelList.dataSource[this.dataSourceDataProperty].forEach(row => {
      if (!isSelected) {
        this.dataSourceFirstLevelList.selection.select(row);
      }
      if (row.secondLevel && row.secondLevel.dataSource.data && row.secondLevel.dataSource.data.length > 0 && row.secondLevel.selected) {
        if (isSelected) {
          row.secondLevel.selected.clear();
        }
        else {
          row.secondLevel.dataSource.data.forEach(childRow => {
            row.secondLevel.selected.select(childRow);
          });
        }
      }
    });
    setTimeout(() => {
      // tslint:disable-next-line:max-line-length
      this.emitRowsSelected({ checked: !isSelected, data: this.dataSourceFirstLevelList.selection['_selected'], selectedList: this.dataSourceFirstLevelList.selection });
    });
  }


  masterClear()
  {
      this.dataSourceFirstLevelList.selection.clear();
      this.dataSourceFirstLevelList.dataSource.data.forEach(row => {
        if (row.secondLevel && row.secondLevel.dataSource.data && row.secondLevel.dataSource.data.length > 0 && row.secondLevel.selected) {
            row.secondLevel.selected.clear();
        }
      });

  }

  onSelectionChange(second, event, field, pData){
    second[field] = event.value;
    second.wbsElementValidation = false;
    let emitCheck = false;
    if (pData.secondLevel.selected.selected.length > 0) {
      pData.secondLevel.selected.selected.filter(sData => {
        if (sData.invoiceId === second.invoiceId) {
          emitCheck = true;
          return;
        }
      });
    }
    if (emitCheck) {
      this.emitRowsSelected({ checked: true, data: [pData], selectedList: this.dataSourceFirstLevelList.selection });
    }
  }
  onSelectRow(event, data) {
    if (event) {
      this.dataSourceFirstLevelList.selection.toggle(data);
      if (event.checked) {
        this.dataSourceFirstLevelList.selection.select(data);
        if (data.secondLevel && data.secondLevel.dataSource && data.secondLevel.dataSource.data.length > 0) {
          data.secondLevel.dataSource.data.forEach(row => data.secondLevel.selected.select(row));
        }
      } else {
        this.dataSourceFirstLevelList.selection.deselect(data);
        if (data.secondLevel && data.secondLevel.dataSource && data.secondLevel.dataSource.data.length > 0) {
          data.secondLevel.dataSource.data.forEach(row => data.secondLevel.selected.deselect(row));
        }
      }
      this.emitRowsSelected({ checked: event.checked, data: [data], selectedList: this.dataSourceFirstLevelList.selection });
    }
  }

  onSelectSecondLevel(event, selData, pData) {
    if (event.checked){
      if (!this.dataSourceFirstLevelList.selection.isSelected(pData)) {
        this.dataSourceFirstLevelList.selection.select(pData);
      }
      pData.secondLevel.selected.select(selData);
    } else {
      pData.secondLevel.selected.deselect(selData);
      if (pData.secondLevel.selected.selected.length === 0) {
        this.dataSourceFirstLevelList.selection.deselect(pData);
      }
    }
    this.emitRowsSelected({ checked: event.checked, data: [pData], selectedList: this.dataSourceFirstLevelList.selection });
  }

  emitRowsSelected(obj) {
    this.emitSelectedRows.emit(obj);
  }

  public toggleList = (data, level, displayLevel, parentData) => {
    if (level === 'secondLevel' && data.eSecondData.length > 0) {
      data = this.cGS.wbsElementValidationCheck(data);
    }
    if (!data[level] || !data[level].displayResults) {
      if (!data[level]) {
        data[level] = {
          dataSource: new MatTableDataSource(data[displayLevel]),
          displayResults: true,
          selected: new SelectionModel<any>(true, [])
        };
      }
      else {
        data[level].displayResults = true;
      }
      // For multi level grid.. should be uncommented later
      // data[level].dataSource.filterPredicate = this.createFilter();
      // const key = this.cGS.findKeys(this.gridData.filterLevels, displayLevel);
      // const filterVal = this.filterValues[key];
      // if (filterVal) {
      //   data[level].dataSource.filter = JSON.stringify(this.filterValues);
      // }
      // }

    } else {
      data[level].displayResults = false;
    }
    // this.sortChanges({
    //   active: this.dataSourceFirstLevelList.dataSource.sort.active,
    //   direction: this.dataSourceFirstLevelList.dataSource.sort.direction
    // });
    this.findFirstLevelData(data, level, displayLevel, data[level].displayResults, parentData);
    this.cgToggleData.emit({ data, level, displayLevel });
  }

  notesIconClick(event){
    if(this.gridData.isNotesEditable){
      event.editMode = true;
      let rowModifyData = { currentItem: event, operation: 'edit' };
      this.rowModifyEvent(rowModifyData);

      const fieldData = { name: 'editNotes', title: 'Notes', comments: event.comments};
          const dialogRef = this.dialog.open(WarningDialogComponent, {
            width: '420px',
            disableClose: true,
            data: fieldData
          });
      const sub = dialogRef.componentInstance.doAction.subscribe((values) => {
        dialogRef.close();
        event.comments = values;
        let modifiedData ={ currentItem: event, comments: values, operation: 'modifyCopy' }
        this.emitNotesEditted.emit(modifiedData);
      });
    }
  }

  rowModifyEvent(event) {
    // event.currentItem.editMode = event.operation === 'edit' ? true : false;
    this.emitRowModify.emit(event);
  }

  addDateRange(data, secondData, second) {
    this.emitAddRow.emit({ parent: data, secondData, addDate: second });
  }

  notesChangeEvent(event) {
    this.emitNotesEditted.emit(event);
  }

  // estimatesEditableMode(e, estimatesDisplayFieldWrapper, estimatesEditable, $event) {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   estimatesDisplayFieldWrapper.classList.add('hide');
  //   estimatesEditable.classList.add('show');
  //   estimatesEditable.children[0].focus();
  // }

  // modifyEstimates(current, parent, level, action, estimatesDisplayFieldWrapper, estimatesEditable) {
  //   this.emitEditted.emit({ currentItem: current, parentItem: parent, levelIndex: level, operation: action });
  //   if ((action === 'F') || action === 'S' || action === 'R') {
  //     estimatesDisplayFieldWrapper.classList.remove('hide');
  //     estimatesEditable.classList.remove('show');
  //   }
  // }

  getEdittedValue($event) {
    this.emitEdittedEstimates.emit($event);
  }


  dynamicCompChangeEvent($event) {
    this.emitEdittedDynamicCompField.emit($event);
  }

  dynamicCompInputFieldBlur($event) {
    this.emitDynamicCompInputFieldBlur.emit($event);
  }

  openModal($event) {
    this.emitClickedData.emit($event);
  }

  emitCurrentData(e, data) {
    this.emitCurrentDataRow.emit(data);
  }
  emitUpdatedRowData(updatedValue, data,columnName, elementRef: any)
  {
    if(this.cS.releaseVersion ==='R2')
    {
    //using draftCommitments as placeholder to store the original value
    if (data.draftCommitments ==  null)
    {
      data.draftCommitments =  data[columnName];
    }
    if (updatedValue !== ''  && parseFloat(updatedValue) !== data.draftCommitments) {
      elementRef.classList.add('highlightAmount');
    } else  {
      elementRef.classList.remove('highlightAmount');
    }
  }

    data[columnName] =updatedValue;
    this.emitCurrentDataRow.emit(data);
  }
  private calculateHeight(calHeight, height, displayResult) {
    if (displayResult) {
      height += calHeight;
    } else {
      height -= calHeight;
    }
    return height;
  }

  toggleHighlight(fourth) {
    fourth['isHiglighted'] = fourth['isHiglighted'] === 'Y' ? 'N' : 'Y';
  }

  private findFirstLevelData(data, level, displayLevel, displayResult, parentData) {
    const calHeight = 40 * (data[displayLevel].length);
    this.dataSourceFirstLevelList.dataSource.data = this.dataSourceFirstLevelList.dataSource.data.filter(lData => {
      if (lData.currentIndex === data.currentIndex && level === 'secondLevel') {
        lData.notesHeight = (!displayResult) ? 40 :
          this.checkChildLevelExpand(data, calHeight, lData.notesHeight, displayResult, level);
      } else if (lData.currentIndex === data.parentIndex && level === 'thirdLevel') {
        lData.notesHeight = this.checkChildLevelExpand(data, calHeight, lData.notesHeight, displayResult, level);
      } else if (level === 'fourthLevel') {
        // const fData = lData.eSecondData.filter((sData: { currentIndex: any; }) => sData.currentIndex === data.parentIndex);
        if (lData.currentIndex === parentData.parentIndex) {
          lData.notesHeight = this.calculateHeight(calHeight, lData.notesHeight, displayResult);
        }
      }
      return lData;
    });
  }

  private checkChildLevelExpand(data, calHeight, height, displayResult, level) {
    if (level === 'secondLevel') {
      data[level].dataSource.data.filter(lData => {
        if (lData.thirdLevel && lData.thirdLevel.dataSource.data.length > 0 && lData.thirdLevel.displayResults) {
          lData.thirdLevel.dataSource.data.filter(nData => {
            if (nData.fourthLevel && nData.fourthLevel.dataSource.data.length > 0 && nData.fourthLevel.displayResults) {
              calHeight += 40 * (nData.fourthLevel.dataSource.data.length);
            }
          });
          calHeight += 40 * (lData.thirdLevel.dataSource.data.length);
        }
      });
    } else if (level === 'thirdLevel') {
      data[level].dataSource.data.filter(lData => {
        if (lData.fourthLevel && lData.fourthLevel.dataSource.data.length > 0 && lData.fourthLevel.displayResults) {
          if (lData.fourthLevel.displayResults) {
            calHeight += 40 * (lData.fourthLevel.dataSource.data.length);
          } else {
            calHeight -= 40 * (lData.fourthLevel.dataSource.data.length);
          }
        }
      });
    }
    return this.calculateHeight(calHeight, height, displayResult);
  }

  isAmount(headerName, data) {
    if (this.amountHeaders.length > 0 && this.amountHeaders.indexOf(headerName) > -1 && (data || data === 0)) {
      return true;
    }
    else {
      return false;
    }
  }

  private updateSelectionModel(data) {
    // if (data) {
    //   this.dataSourceFirstLevelList.dataSource.data = data;
    // }
    this.dataSourceFirstLevelList.dataSource.data.filter(fLevel => {
      if (fLevel.eSecondData && fLevel.eSecondData.length > 0) {
        if ((!fLevel.secondLevel) ||
          (fLevel.secondLevel && fLevel.eSecondData.length !== fLevel.secondLevel.dataSource.data.length)) {
          fLevel.secondLevel = {
            dataSource: new MatTableDataSource(fLevel.eSecondData),
            displayResults: false,
            selected: new SelectionModel<any>(true, [])
          };
          //if (level === 'secondLevel') {
            fLevel.secondLevel.dataSource.sort = this.secondData;
            fLevel.secondLevel.dataSource.filterPredicate = this.createFilter();
          //} else if (level === 'thirdLevel') {
            // data[level].dataSource.sort = this.thirdData;
          //}
          //const calHeight = 48 * (fLevel.eSecondData.length);
          //fLevel.notesHeight = this.checkChildLevelExpand(fLevel, calHeight, 48, true, 'secondLevel');
        }
        if (fLevel.secondLevel && (fLevel.secondLevel.dataSource.data.length > 0)) {
          fLevel.secondLevel.dataSource.data.filter(sLevel => {
            if (sLevel.eThirdData && sLevel.eThirdData.length > 0) {
              if ((!sLevel.thirdLevel) ||
                (sLevel.thirdLevel && sLevel.eThirdData.length !== sLevel.thirdLevel.dataSource.data.length)) {
                sLevel.thirdLevel = {
                  dataSource: new MatTableDataSource(sLevel.eThirdData),
                  displayResults: false,
                  selected: new SelectionModel<any>(true, [])
                };
                sLevel.thirdLevel.dataSource.sort = this.thirdData;
                sLevel.thirdLevel.dataSource.filterPredicate = this.createFilter();
                //const calHeight = 48 * (sLevel.eThirdData.length);
                //fLevel.notesHeight = this.checkChildLevelExpand(sLevel, calHeight, 96, true, 'thirdLevel');
              }
              if (sLevel.thirdLevel && sLevel.thirdLevel.dataSource.data.length > 0) {
                sLevel.thirdLevel.dataSource.data.filter(tLevel => {
                  if (tLevel.eFourthData && (tLevel.eFourthData.length > 0)) {
                    if ((!tLevel.fourthLevel) ||
                      (tLevel.fourthLevel && tLevel.eFourthData.length !== tLevel.fourthLevel.dataSource.data.length)) {
                      tLevel.fourthLevel = {
                        dataSource: new MatTableDataSource(tLevel.eFourthData),
                        displayResults: false,
                        selected: new SelectionModel<any>(true, [])
                      };
                      tLevel.fourthLevel.dataSource.sort = this.thirdData;
                      tLevel.fourthLevel.dataSource.filterPredicate = this.createFilter();
                      //const calHeight = 48 * (sLevel.eThirdData.length);
                      //fLevel.notesHeight = this.checkChildLevelExpand(sLevel, calHeight, 96, true, 'thirdLevel');
                    }
                  }

                });
              }

            }
          });
        }
      }
    });
  }

  private compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  collapseAll() {
    const gridDataTotalCount = this.gridData.data.length;
    if (gridDataTotalCount) {
      this.gridData.data.filter(first => {
        if (first.secondLevel) {
          first.eSecondData.filter(second => {
            if (second.thirdLevel) {
              second.eThirdData.filter(third => {
                if (third.fourthLevel) {
                  third.fourthLevel.displayResults = false;
                }
              });
              second.thirdLevel.displayResults = false;
            }
          });
          first.secondLevel.displayResults = false;
        }
        first.notesHeight = 40;
      });
    }
  }

  // MAFN-337
  expandAll() {
    const gridDataTotalCount = this.gridData.data.length;
    if (gridDataTotalCount) {
      this.gridData.data.forEach(first => {
        let recordRows = 1;
        if (first.secondLevel) {
          first.eSecondData.forEach(second => {
            recordRows++;
            if (second.thirdLevel) {
              second.eThirdData.forEach(third => {
                recordRows++;
                if (third.fourthLevel) {
                  recordRows = recordRows + third.eFourthData.length;
                  third.fourthLevel.displayResults = true;
                }
              });
              second.thirdLevel.displayResults = true;
            }
          });
          first.secondLevel.displayResults = true;
        }
        if(first.notesHeight){
          first.notesHeight = recordRows * 40;
        }
      });
    }
  }

  // on click of filter button
  filterBtnClk(showFilter) {
  this.filterEnabled = showFilter;
  if (this.filterEnabled) {
        let checkFirst = true;
        this.filterSelectObj.filter((o) => {
          if(o.options){
          o.options = [];

          o = this.getFilterOptionAfterFilter(o, this.dataSourceFirstLevelList, 'data');
        }
        });
    }
    if (!this.filterEnabled) { this.resetFilters(); }
  }

  //To populate filter menu options
  getFilterOptionAfterFilter(options, gridData, dataString) {
    if (gridData.dataSource && gridData.dataSource[dataString].length > 0) {
      if (options.filterMode) {
        gridData.dataSource[dataString].forEach(flData => {
          if (flData.secondLevel && flData.secondLevel.dataSource && flData.secondLevel.dataSource[dataString].length > 0) {
            if (options.filterMode === 'secondLevel') {
              options = this.cGS.getFilterOptions(flData.secondLevel.dataSource[dataString], options);
            }
            else {
              flData.secondLevel.dataSource[dataString].forEach(slData => {
                if (slData.thirdLevel && slData.thirdLevel.dataSource && slData.thirdLevel.dataSource[dataString].length > 0) {
                  if (options.filterMode === 'thirdLevel') {
                    options = this.cGS.getFilterOptions(slData.thirdLevel.dataSource[dataString], options);
                  }
                  else {
                    slData.thirdLevel.dataSource[dataString].forEach(tlData => {
                      if (tlData.fourthLevel && tlData.fourthLevel.dataSource && tlData.fourthLevel.dataSource[dataString].length > 0) {
                        if (options.filterMode === 'fourthLevel') {
                          options = this.cGS.getFilterOptions(tlData.fourthLevel.dataSource[dataString], options);
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
      else {
        options = this.cGS.getFilterOptions(gridData.dataSource[dataString], options);
      }
    }
    if (options.options.length > 0) {
      options = this.cGS.getFilteredValue(options);
    }
    return options;
  }

  // Get Unique values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  dateRangeFilterChange(start, end, index, event) {
    if (start && end){
      this.dateRangeFilterText = start +'-'+ end;
      this.filterChange(index, {name: this.dateRangeFilterText, checked:true},event);
    }
  }
  clearDateRangeFilter(index,dateStart,dateEnd){
    dateStart.value ='';
    dateEnd.value = '';
    this.filterChange(index, {name: '', checked:false}, null);
  }

  // Called on Filter change
  filterChange(index, item, event,filterInput?) {
    const that = this;
    let filter = this.filterSelectObj[index];
    if (item && !filter.filterType){
      item.checked = !item.checked;
      if (!item.name && item.name !== 0) { item.name = ''; }
    }

    if (item && item.checked) {
      filter.optionselected = true;
      if (!this.filterValues[filter.name]) { this.filterValues[filter.name] = []; }
      if(filter.filterType && filter.filterType ==='daterange'){
        this.filterValues[filter.name] = [];
      }
      // else{

        this.filterValues[filter.name].push(item.name.toString().trim());
        if (this.filterAnchor && this.filterAnchor == '') { this.filterAnchor = filter.name; }
        else if (!this.filterAnchor) { this.filterAnchor = filter.name; }
      //}

        this.checkLevelnFilter(filter);
        if (this.emitFilterData){
          this.emitFilteredData.emit({
            data: this.dataSourceFirstLevelList.dataSource.filteredData,
            column: filter.name,
            clickedValue: item.name.toString().trim(),
            checked: true
          });
        }
    }
    else {
      if(filter.filterType && filter.filterType ==='daterange'){
        this.filterValues[filter.name] = [];
      }
      else{
        let removeIndex = this.filterValues[filter.name].indexOf(item.name.toString().trim());
        if (removeIndex > -1) {
          this.filterValues[filter.name].splice(removeIndex, 1);
        }
      }

      let removeFilter = Object.keys(this.filterValues).every((key) => {
        return that.filterValues[key].length === 0;
      });
      if (that.filterValues[filter.name].length === 0) { filter.optionselected = false; }
      if (removeFilter) {
        // this.resetFilters();
        this.filterValues = {};
        this.filterAnchor = '';
        this.clearAllFilters();
        filter.optionselected = false;
      }
      else {
        for (const col in this.filterValues) {
          if (this.filterValues[col].length <= 0) {
            delete this.filterValues[col];
          }
        }
        if (!this.filterValues[this.filterAnchor])  { this.filterAnchor = Object.keys(this.filterValues)[0]; }
        this.checkLevelnFilter(filter);
      }
      if (this.emitFilterData){
        this.emitFilteredData.emit(
          {
            data: this.dataSourceFirstLevelList.dataSource.filteredData,
            column: filter.name,
            clickedValue: item.name.toString().trim(),
            checked: false
          });
      }
    }
    this.filterSelectObj.filter((o) => {
      if (this.filterAnchor !== o.name && o.options && !o.filterType) { // &&  filter.name !== o.name
        o.options = o.options.filter(row => row.checked);
        let dataString = this.dataSourceFirstLevelList.dataSource.filteredData.length > 0 ?
          'filteredData' : 'data';
        this.getFilterOptionAfterFilter(o, this.dataSourceFirstLevelList, dataString);
      }
      if(filterInput && filterInput.value && filter.name === o.name){
        o.filteredOptions = o.options.filter(obj => obj.name && obj.name.toString().toLowerCase().includes(filterInput.value.toLowerCase()));
      }
    });
    if(!filter.filterType){
      event.stopPropagation();
    }
  }

  //Apply filter at required level
  checkLevelnFilter(currentFilter) {
    let filterMode = currentFilter.filterMode;
    if (filterMode) {
      this.dataSourceFirstLevelList.dataSource.data.forEach(flData => {
      if (flData.secondLevel && flData.secondLevel.dataSource){
        if (filterMode !== 'secondLevel') {
            flData.secondLevel.dataSource.data.forEach(slData => {
            if (slData.thirdLevel && slData.thirdLevel.dataSource){
              if (filterMode !== 'thirdLevel') {
                slData.thirdLevel.dataSource.data.forEach(tlData =>{
                  if (filterMode === 'fourthLevel') {
                    this.level = filterMode;
                    if (tlData.fourthLevel && tlData.fourthLevel.dataSource){
                      tlData.fourthLevel.dataSource.filter = JSON.stringify(this.filterValues);
                    }
                  }
                });
              }
              this.level = 'thirdLevel';
              slData.thirdLevel.dataSource.filter = JSON.stringify(this.filterValues);
              }
            });
          }
          this.level = 'secondLevel';
          flData.secondLevel.dataSource.filter = JSON.stringify(this.filterValues);
        }
      });
    }
    this.level = null;
    this.dataSourceFirstLevelList.dataSource.filter = JSON.stringify(this.filterValues);
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let that = this;
    let filterFunction = function (data: any, filter: string): boolean {
      const searchTerms = JSON.parse(filter);

      let nameSearch = () => {
        const found = false;
        const foundData: boolean[] = [];
        let isChildLevel = false;
        // if (isFilterSet) {
        for (const col in searchTerms) {
          let immediateChild = 'secondLevel';
          let currentLevelFilter, childLevelFilter;
          if (!that.level) {
            currentLevelFilter = that.filterSelectObj.find(row => row.name === col && !row.filterMode);
          }
          else {
            currentLevelFilter = that.filterSelectObj.find(row => row.name === col && row.filterMode && row.filterMode === that.level);
            immediateChild = that.filterLevels[that.filterLevels.indexOf(that.level)+1];
            childLevelFilter = that.filterSelectObj.find(row => row.name === col && row.filterMode && row.filterMode === immediateChild);
          }
          if (currentLevelFilter) {

            if(data[immediateChild]&& data[immediateChild].dataSource.data.length >0 && data[immediateChild].dataSource.filteredData.length === 0){
              foundData.push(false);
            }
            else if(currentLevelFilter.filterType && currentLevelFilter.filterType === 'daterange'){
              let dates = searchTerms[col][0].split('-');
              let startDate = new Date(dates[0]);
              let endDate = new Date(dates[1]);
              let dateData = new Date(data[col]);
              if((dateData.getTime() <= endDate.getTime() && dateData.getTime() >= startDate.getTime())){
                foundData.push(true);
              }
            }
            else
            {
              let validateData = '';
              if (data[col] || data[col] == "") {
                validateData = data[col].toString().trim().toLowerCase();
                if (searchTerms[col].filter(item => item.trim().toLowerCase() == validateData).length > 0) { // && isFilterSet
                  foundData.push(true);
                }
                else { foundData.push(false); }
              }
            }
          }
          else {
            if(data[immediateChild] && data[immediateChild].dataSource.data.length >0 && data[immediateChild].dataSource.filteredData.length === 0 ||
              (childLevelFilter && childLevelFilter.optionalDS && !data[immediateChild])){
              foundData.push(false);
            }
            else{
              foundData.push(true);
            }
          }
        }
        if (foundData.length <= 0) { return false; }
        for (var i in foundData) {
          if (!foundData[i]) { return false; }
        }
        return true;
      };
      return nameSearch();
    };
    return filterFunction;
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {};
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
      value.optionselected = false;
      if (value.options){
        value.options.forEach(element => {
          element.checked = false;
        });
        value.filteredOptions = value.options.slice();
      }
    });

    this.filterAnchor = '';
    this.clearAllFilters();
    if (this.emitFilterData){
      this.emitFilteredData.emit({
              data: this.dataSourceFirstLevelList.dataSource.filteredData,
              column: null,
              clickedValue: null,
              checked: false
            });
    }
  }

  clearAllFilters() {
    this.dataSourceFirstLevelList.dataSource.filter = '';
    if (this.dataSourceFirstLevelList.dataSource.data && this.dataSourceFirstLevelList.dataSource.data.length > 0) {
      this.dataSourceFirstLevelList.dataSource.filter = '';
      this.dataSourceFirstLevelList.dataSource.data.forEach(parent => {
        if (parent.secondLevel && parent.secondLevel.dataSource.data && parent.secondLevel.dataSource.data.length > 0) {
          parent.secondLevel.dataSource.filter = '';
          parent.secondLevel.dataSource.data.forEach(second => {
            if (second.thirdLevel && second.thirdLevel.dataSource.data && second.thirdLevel.dataSource.data.length > 0) {
              second.thirdLevel.dataSource.filter = '';
              second.thirdLevel.dataSource.data.forEach(third => {
                if (third.fourthLevel && third.fourthLevel.dataSource.data && third.fourthLevel.dataSource.data.length > 0) {
                  third.fourthLevel.dataSource.filter = '';
                }
              });
            }
          });
        }
      });
    }
  }

  menuSelection(item) {
    const emitVal = { data: this.selectedMenuRow, action: item.action};
    this.emitMenuItem.emit(emitVal);
    this.selectedMenuRow = {};
  }

  selRow(data){
    this.selectedMenuRow = data;
  }

  filterSelectItems(index, event) {
    event.stopPropagation();
    if (event.target.value) {
      const text = event.target.value;
      // tslint:disable-next-line:max-line-length
      this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.filter(obj => obj.name.toString().toLowerCase().includes(text.toLowerCase()));
    } else {
      this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.slice();
    }
  }

  orderByWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
    const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
    if (pAttr == 'name') {
    return _.concat(partition[1], _.orderBy(partition[0], (i) => typeof i.name == 'string' && i.name.toLowerCase(), (pReverse ? 'desc' : 'asc')));
    }
    else { return _.concat(partition[1], _.orderBy(partition[0], pAttr, (pReverse ? 'desc' : 'asc'))); }
  }
  orderByDateWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
    const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
    return _.concat(partition[1], _.orderBy(partition[0], function(dateObj) {
    return new Date(dateObj.name);
  }));
  }

  hideFilter(hide){
    if (this.filterEnabled){
      this.resetFilters();
    }
      this.filterEnabled = false;
      this.emitShowFilter.emit(false);
    // }
  }
  resetFilteredOptions(index){
    const filter = this.filterSelectObj[index];
    if (filter.options){
      if (filter.options.length > 0){
        filter.filteredOptions = filter.options.slice();
      }
      else{
        filter.filteredOptions = [];
      }
    }
  }

  deleteCurrentRow(current, parent){
    this.emitDeleteRow.emit({ currentItem: current, parentItem: parent });
  }

  getSelectedFilterValue(filter) {
    if (this.filterValues[filter.name] && this.filterValues[filter.name].length) {
      if (this.filterValues[filter.name].length === 1) {
        return this.filterValues[filter.name][0];
      }
      else {
        return 'Multiple';
      }
    }
    else
    {
      return 'Select';
    }
  }

  // MAFN-382, grand total displayed in footer row
  getTotal(name) {
    return this.dataSourceFirstLevelList.dataSource.filteredData.map(t => t[name]? parseInt(this.replaceAll(formatNumber(t[name],this.locale,'1.0-0'),',','')): 0).reduce((acc, value) => acc + value, 0);
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  zeroIfNegative(data,header){
    if(header.zeroIfNegative && data[header.name] < 0){
      return 0;
    }
    else
    return data[header.name];
  }
  getValue(template) {
  }
}

