<div class='mediaAppHeader' *ngIf="isLoggedIn$ | async as isLoggedIn">
  <section class="user">
    <mat-slide-toggle
        class="mattoggle"
        [checked]="isR2"
        (change)="toggleRelease($event.checked)">
     R2
    </mat-slide-toggle>
  </section>
  <nav class="topBar"><span class="user">Welcome {{user}} |
    <mat-icon class="align-middle signOut" (click)="logoutUser()">power_settings_new</mat-icon>
    <!-- <fa-icon [icon]="faPowerOff" #tooltip="matTooltip" matTooltip="Logout" class="align-middle signOut" (click)="logoutUser()"></fa-icon> -->
  </span></nav>
    <div>
        <a class="hdlft" [routerLink]="logoRedirectionUrl" >
             <!-- <img alt="NBCUniversal" height="24" width="180" src="../../../../assets/images/NBCUniversal_logo.png"> -->
             <img alt="PACE" height="46" width="121" src="../../../../assets/images/pace_logo.png">
        </a>

        <nav mat-tab-nav-bar>
            <!-- <a mat-tab-link *ngFor="let link of navlinks" (click)="activeLinkIndex = link.index"
                [routerLink]="link.link" [active]="activeLink === link"> -->
            <a mat-tab-link *ngFor="let link of navlinks" (click)="activeLink = link"
              [routerLink]="link.link" routerLinkActive="active" [active]="activeLink === link">
                {{link.label}}
            </a>
        </nav>

    </div>
</div>
<app-spinner [loadingIcon]="loadingIcon"></app-spinner>
