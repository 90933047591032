import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {

  constructor(private router:Router,private authService: AuthService) { }
  public validationMsg = this.authService.loggedInValidation;

  ngOnInit(): void {

    if((window.performance.getEntries()[0] as PerformanceNavigationTiming).type  === 'reload'){
      this.router.navigateByUrl('/');
    }
  }


}
