<div class="editEstimates">
    <span #estimatesDisplayFieldWrapper class="estimates-displayField-wrapper" fxLayout="row" fxLayout.xs="column"
        fxLayoutWrap>
        <span fxFlex="100">
            <span fxFlex="76" [matTooltip]="current[header.name]" content-type="template" placement="top" show-delay="100"
                hide-delay="500" [ngClass]="{'text-edited' : current['isEdited']}"
                class="estimateTextCell">{{isAmount(header.name,current[header.name])? (current[header.name] | number :'1.0-0' | ellipsis:15): current[header.name]}}</span>
            <span fxFlex="12" (click)="estimatesEditableMode($event, estimatesDisplayFieldWrapper, estimatesEditable)"
                class="material-icons edit-icon">edit</span>
            <span fxFlex="12" class="material-icons" *ngIf="current['isEdited']"
                class="grid_arrows material-icons edit-icon"
                (mousedown)="modifyEstimates(current,parent,levelIndex, 'R',estimatesDisplayFieldWrapper, estimatesEditable, $event)">
                clear
            </span>
        </span>
    </span>
    <!-- TODO: Refactor show error messages outside of tooltip -->
    <span #estimatesEditable [ngClass]="{sapActualsEditable:header.name === 'serviceMonth',estimatesEditable:header.name != 'serviceMonth'}" [tooltip]="errorMessage" [display]="showTooltip"
        content-type="template" placement="right" show-delay="100" hide-delay="500">
        <input #estimateInp matInput type="text" appValidationFormat="dec2" maxlength="10"
            (blur)="modifyEstimates(current,parent,levelIndex,'F',estimatesDisplayFieldWrapper, estimatesEditable, $event)"
            [(ngModel)]="current[header.name]"
            (ngModelChange)="modifyEstimates(current,parent,levelIndex, 'M',estimatesDisplayFieldWrapper, estimatesEditable, $event)" />
        <mat-icon class="grid_arrows"
            (mousedown)="modifyEstimates(current,parent,levelIndex, 'R',estimatesDisplayFieldWrapper, estimatesEditable, $event)">
            clear</mat-icon>
        <!-- <mat-icon class="grid_arrows"
         (mousedown)="modifyEstimates(current,parent,levelIndex,'S',estimatesDisplayFieldWrapper, estimatesEditable, $event)">
         check</mat-icon> -->
    </span>
</div>

<ng-template #errorMessage>
    <div class="parentEstimateWarning">
        <div class="div-warning-icon">
            <mat-icon class="warning-icon">warning</mat-icon>
        </div>
        <div class="estimateWarning">Changing the show level estimate will override the associated media type estimates
            to
            the default percentages</div>
    </div>
</ng-template>
