<div class="common-grid-container">
    <mat-table #table [dataSource]="dataSourceFirstLevelList.dataSource" matSort #firstData="matSort"
        [matSortActive]="defaultSort" [matSortDirection]="defaultSortDirection" matSortDisableClear
        class="top-mat-table" (matSortChange)="sortChanges($event)">
        <ng-container *ngIf="gridData.topColumns && gridData.topColumns.display">
            <ng-container *ngIf="gridData.topColumns.display" matColumnDef="no-group-left">
                <mat-header-cell *matHeaderCellDef fxFlex="{{gridData.topColumns.f}}"></mat-header-cell>
            </ng-container>

            <ng-container *ngIf="gridData.topColumns.display" matColumnDef="io-group">
                <mat-header-cell *matHeaderCellDef class="io-group" fxFlex="{{gridData.topColumns.s}}">Issued IOs
                </mat-header-cell>
            </ng-container>

            <ng-container *ngIf="gridData.topColumns.display" matColumnDef="no-group-right">
                <mat-header-cell *matHeaderCellDef fxFlex="{{gridData.topColumns.t}}"></mat-header-cell>
            </ng-container>
            <mat-header-row class="upper-header" *matHeaderRowDef="['no-group-left', 'io-group', 'no-group-right']">
            </mat-header-row>
        </ng-container>
        <mat-header-row class="lower-header" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <ng-container *ngFor="let header of gridData.columns;let colIndex= index" matColumnDef="{{header.name}}">
            <mat-header-cell [ngClass]="{'multiRowheaderPadding': (isMultiRowHeader && !filterEnabled)}"
                *matHeaderCellDef class="{{header.style}}" fxFlex="{{header.width}}">
                <ng-container *ngIf="header.name === 'totalCommitmentAmount'">
                </ng-container>
                <ng-container *ngIf="header.name === 'chkSelection' || header.name === 'menu';else otherHeaders">
                    <ng-container *ngIf="header.name === 'chkSelection'">
                        <span class="matheaderLabel" [ngClass]="{'filterSpacing': filterEnabled}">
                            <mat-checkbox class="headerCheckbox" (click)="$event.stopPropagation()" (change)="$event ? masterToggle() : null"
                                [checked]="dataSourceFirstLevelList.selection.hasValue() && isAllSelected()"
                                [indeterminate]="dataSourceFirstLevelList.selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="header.name === 'menu'">
                        <!-- <button mat-icon-button><mat-icon>more_vert</mat-icon></button> -->
                    </ng-container>
                </ng-container>
                <ng-template #otherHeaders><span class="matheaderLabel" [ngClass]="{'filterSpacing': filterEnabled}"
                        mat-sort-header [disabled]="!header.sort">
                        {{header.label}}<ng-container
                            *ngIf="header.name === 'estimate' && gridData.info.versionToolTipInfo">
                            <span #infoIcon class="material-icons info-icon" [matTooltip]="versionMetaData"
                                content-type="template" placement="top" show-delay="100" hide-delay="100">info
                            </span>
                        </ng-container>
                    </span>
                </ng-template>
                <div class="filterRowContainer media-form" *ngIf="filterEnabled" (click)="resetFilteredOptions(colIndex);$event.stopPropagation()">
                  <div class="filterRowChildContainer" *ngIf="header.filterable">
                    <ng-container *ngIf="(filterSelectObj[colIndex].filterType && filterSelectObj[colIndex].filterType ==='daterange');else genericFilter">
                      <mat-form-field class="dateRangeFilter" appearance="fill" fxFlex="38">
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate #dateStart readonly>
                          <input matEndDate #dateEnd readonly (dateChange)="dateRangeFilterChange(dateStart.value,dateEnd.value,colIndex,$event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                      <mat-icon class="clearDateRange" (click)="clearDateRangeFilter(colIndex, dateStart, dateEnd)" fxFlex="20">close</mat-icon>
                    </ng-container>
                    <ng-template #genericFilter>
                      <div class="filterRowCell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{index: colIndex}">
                        <label class="selectedfiltVal">{{getSelectedFilterValue(filterSelectObj[colIndex])}}</label>
                        <mat-icon class="filterDownArrow" [ngClass]="{'hasSelection':filterValues[filterSelectObj[colIndex].name]}">arrow_drop_down</mat-icon>
                      </div>
                    </ng-template>
                  </div>
                  </div>
            </mat-header-cell>
            <mat-cell #myCell *matCellDef="let data" fxFlex="{{header.width}}" class="grouping-row"
                [ngClass]="{'editEstimateInline' : (((header.name === 'estimate' || header.name === 'serviceMonth') && data['isEditable']) && data['isEdited']),'redFontStyle': (header.name === 'expectedBalance' && data['expectedBalance'] < 0  ),'currencyField':(header.type==='currency' || header.inputType==='currency')}"
                (click)="$event.stopPropagation();">

                <div fxFlex="100"
                                    [ngClass]="{'grouping-row-expansionColumn': data?.showTree && data.showTree[header.name],'showDetailIconDisplay':header.showDetail,'currencyField':(header.type==='currency')}">
                  <ng-container *ngIf="header.name === 'totalCommitmentAmount'">
                    <app-editable-field-component
                      [centerAlign]="false"
                      [stackButtonsVertically]="true"
                      [editableByDefault]="false"
                      [value]="data.totalCommitmentAmount"
                      [needValidation]="true"
                      [compareValue]="data.issuedCommitments"
                      (newValue) ="emitUpdatedRowData($event,data,'totalCommitmentAmount',myCell)"
                    ></app-editable-field-component>
                  </ng-container>
                  <ng-container *ngIf="header.name === 'remainingBalanceToAllocate' || header.name === 'allocatedToFlights'" style="text-align: right;" >
                    <span >{{data[header.name]| number:'1.0-0' | minusSignToParens}}</span>
                  </ng-container>
                    <app-row-modify
                        *ngIf="data?.showTree && data?.showTree[header.name] && gridData.rowModify && gridData.rowModify.save"
                        [data]="data" (rowModify)="rowModifyEvent($event)">
                    </app-row-modify>
                    <input type="checkbox" style="display: none;" *ngIf="data?.showTree && data?.showTree[header.name] && gridData.rowModify.checkbox"
                        class="float-left" />
                    <span class="float-left flexAlignIcon">
                        <mat-icon class="grid_arrows"
                            *ngIf="data?.showTree && data.showTree[header.name] && !data?.secondLevel?.displayResults"
                            (click)="toggleList(data, 'secondLevel', 'eSecondData', null);$event.stopPropagation();">
                            arrow_right
                        </mat-icon>
                    </span>
                    <span class="float-left flexAlignIcon">
                        <mat-icon class="grid_arrows"
                            *ngIf="data?.showTree && data.showTree[header.name] && data?.secondLevel?.displayResults"
                            (click)="toggleList(data, 'secondLevel', 'eSecondData', null);$event.stopPropagation();">
                            arrow_drop_down</mat-icon>
                    </span>
                    <ng-container
                        *ngIf="header.name === 'chkSelection' || header.name === 'menu';else header.name === 'validations' ? validationField : otherFields">
                        <ng-container *ngIf="header.name == 'chkSelection'">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="onSelectRow($event, data)"
                                [checked]="dataSourceFirstLevelList.selection.isSelected(data)" [indeterminate]="
                            data?.secondLevel && (
                            data?.secondLevel?.selected?.selected.length > 0 &&
                            data?.secondLevel?.selected?.selected.length !== data?.secondLevel?.dataSource?.data.length
                        )">
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="header.name === 'menu'">
                            <button mat-icon-button [matMenuTriggerFor]="accrualsMenu" (click)="selRow(data);">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-template #validationField>
                        <app-validations-display [data]="data" [parent]="null"></app-validations-display>
                    </ng-template>
                    <ng-template #otherFields>
                        <ng-container *ngIf="gridData.rowModify && gridData.rowModify.save; else notRowModify">
                            <ng-container *ngIf="(header.name === 'comments'|| header.name ==='note')? notesPopupIcon : otherThanNotes">
                                <app-notes [header]="header" [data]="data"
                                    [inlineSave]="gridData.field.notes.inlineSave" [rowEdit]="data.editMode"
                                    (hidenResetFilter)="hideFilter($event)" (notesChange)="notesChangeEvent($event)">
                                </app-notes>
                            </ng-container>
                            <ng-template #otherThanNotes>
                                <span
                                    [matTooltip]="isAmount(header.name,data[header.name])? (zeroIfNegative(data,header) | number: numberFormat | minusSignToParens): data[header.name]"
                                    [ngClass]="{'negative': header.name === 'variance'&& (data[header.name] < 0), 'currencyField':(header.type=='currency')}"
                                    *ngIf="(!data.editMode || ((data.editMode && !header.editable) || (data.editMode && header.editable && !header.editableFirstLevel)))"
                                    class="text-span">{{isAmount(header.name,data[header.name])? (zeroIfNegative(data,header) | number: numberFormat | minusSignToParens): data[header.name]}}</span>
                                <app-dynamic
                                    *ngIf="data.editMode && header.editable && header.editableFirstLevel && header.name !== 'comments'"
                                    [data]="data" [field]="header" [selectOptions]="gridData.selectOptions"
                                    (dynamicCompChange)="dynamicCompChangeEvent($event)"
                                    (dynamicCompInputFieldBlur)="dynamicCompInputFieldBlur($event)">
                                </app-dynamic>
                            </ng-template>
                        </ng-container>
                        <ng-template #notRowModify>
                             <ng-container
                                *ngIf="header.name === 'invoiceId' || header.name === 'wbsElement' || header.name === 'invoiceNo';else nonLinkFields">
                                <span  *ngIf="header.name === 'invoiceId' || header.name === 'invoiceNo'" class="text-span"><a class="linkField"
                                        (click)="openModal(data);$event.stopPropagation();">{{data[header.name]}}</a></span>
                                        <span *ngIf="(header.name === 'wbsElement')" class="text-span">{{data[header.name]}}</span>
                            </ng-container>
                            <ng-template #nonLinkFields>
                                <ng-container
                                    *ngIf="header.name !== 'comments' && header.name !== 'note'
                                     && (!((header.name == 'estimate' || header.name === 'serviceMonth') && data['isEditable'])); else header.name === 'comments'? notesPopupIcon : (header.name === 'note'? notesPopupIcon: estimateField)">
                                    <span *ngIf="(header.name !== 'totalCommitmentAmount' && header.name !== 'remainingBalanceToAllocate' && header.name !== 'allocatedToFlights')" [ngClass]="{'negative': header.name === 'variance'&& (data[header.name] < 0),'currencyField':(header.type==='currency'|| header.inputType==='currency')}"
                                        class="text-span" [matTooltip]="isAmount(header.name,data[header.name])? (zeroIfNegative(data,header) | number: numberFormat | minusSignToParens): data[header.name]" >{{isAmount(header.name,data[header.name])? (zeroIfNegative(data,header) | number: numberFormat | minusSignToParens): data[header.name]}}
                                    <ng-container *ngIf="header.showDetail && header.name !== 'totalCommitmentAmount' && header.name !== 'remainingBalanceToAllocate' && header.name !== 'allocatedToFlights'">
                                      <span class="material-icons show-detail-icon" (click)="emitCurrentData($event,data)">info</span>
                                    </ng-container>
                                </span>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                        <ng-template #notesColumn>
                            <app-notes [header]="header" [data]="data" [inlineSave]="gridData.field.notes.inlineSave"
                                (hidenResetFilter)="hideFilter($event)" (notesChange)="notesChangeEvent($event)">
                            </app-notes>
                        </ng-template>
                        <ng-template #notesPopupIcon>
                            <div class="notesIconContainer">
                                <span *ngIf="data[header.name]">
                                    <fa-icon class="notesIcon" [ngClass]="{'notesIconEnabled': gridData.isNotesEditable}" (click)="notesIconClick(data)" [icon]="fasCommentAlt" [matTooltip]="data[header.name]"></fa-icon>
                                </span>
                                <span *ngIf="!data[header.name]">
                                    <fa-icon class="notesIcon" [ngClass]="{'notesIconEnabled': gridData.isNotesEditable}" (click)="notesIconClick(data)" [icon]="faCommentAlt"></fa-icon>
                                </span>
                            </div>
                            <ng-template #notesData>
                              <span>{{data[header.name]}}</span>
                            </ng-template>
                        </ng-template>
                        <ng-template #estimateField>
                            <app-edit-estimate [header]="header" [current]="data" [parent]="null" [showTooltip]="true"
                                [levelIndex]="0" (hidenResetFilter)="hideFilter($event)"
                                (emitEditted)="getEdittedValue($event)"></app-edit-estimate>
                        </ng-template>
                    </ng-template>
                </div>
            </mat-cell>
            <ng-container *ngIf="showFooter">
            <mat-footer-cell *matFooterCellDef  fxFlex="{{header.width}}" class="grouping-row currencyFooter">
                <ng-container *ngIf="header.displayTotal; else noTotal">
                    <span class="text-span currencyField" [matTooltip]="getTotal(header.name) | number: numberFormat | minusSignToParens">{{(getTotal(header.name) | number: numberFormat | minusSignToParens)}}</span>
                </ng-container>
                <ng-template #noTotal>
                    <span class="text-span">{{colIndex === 0? 'Total':''}}</span>
                </ng-template>
            </mat-footer-cell>
        </ng-container>
        </ng-container>
        <mat-row class="first-row-header" *matRowDef="let data; columns: displayedColumns;"
            [cdkDetailRowDisplayTpl]="data.eSecondData?.length && data?.secondLevel?.displayResults"
            [cdkDetailRow]="data" [cdkDetailRowTpl]="secondLevelListingTemplate">
        </mat-row>
        <ng-container *ngIf="showFooter">
        <mat-footer-row class="total-row" *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
    </ng-container>
    </mat-table>
    <ng-template #secondLevelListingTemplate let-data>
        <div [@detailExpand] style="overflow: hidden">
            <mat-table [dataSource]="data.secondLevel.dataSource" matSort #secondData="matSort"
                (matSortChange)="sortChangesSecondLevel($event, data)" [ngStyle]="{'background-color':'white'}"
                [ngClass]="{'new-table-level2' : displayNewTableLevel2}">
                <ng-container *ngIf="displayNewTableLevel2">
                    <mat-header-row class="lower-header" *matHeaderRowDef="displayedColumns2; sticky: true">
                    </mat-header-row>
                </ng-container>
                <ng-container *ngIf="displayNewTableLevel2 else sameTable">
                    <ng-container *ngFor="let header of gridData.columns2" matColumnDef="{{header.name}}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!header.sort"
                            fxFlex="{{header.width}}">{{header.label}}</mat-header-cell>
                        <mat-cell *matCellDef="let second" fxFlex="{{header.width}}"
                            [ngClass]="{'editEstimateInline' : header.name === 'estimate' && second['isEditable'] && second['isEdited'], 'currencyField':(header.type==='amountFieldEditable') }"
                            class="grouping-row2" (click)="$event.stopPropagation();">
                            <div fxFlex="100"
                                [ngClass]="{'grouping-row-expansionColumn': second?.showTree[header.name]}">
                                <span class="float-left flexAlignIcon">
                                    <mat-icon class="grid_arrows"
                                        *ngIf="second?.showTree[header.name] && !second?.thirdLevel?.displayResults"
                                        (click)="toggleList(second, 'thirdLevel', 'eThirdData', data);$event.stopPropagation();">
                                        arrow_right</mat-icon>
                                </span>
                                <span class="float-left flexAlignIcon">
                                    <mat-icon class="grid_arrows"
                                        *ngIf="second?.showTree[header.name] && second?.thirdLevel?.displayResults"
                                        (click)="toggleList(second, 'thirdLevel', 'eThirdData', data);$event.stopPropagation();">
                                        arrow_drop_down</mat-icon>
                                </span>
                                <ng-container
                                    *ngIf="header.name == 'chkSelection';else header.name === 'validations' ? validationField : otherFields">
                                    <ng-container
                                        *ngIf="second.iSelectionMessage && second.iSelectionMessage !== ''; else checkboxField">
                                        <span class="material-icons save-icon iMessage"
                                            [matTooltip]="second.iSelectionMessage"
                                            content-type="template" placement="bottom" show-delay="100"
                                            hide-delay="100">check</span>
                                    </ng-container>
                                    <ng-template #checkboxField>
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="onSelectSecondLevel($event, second, data)"
                                            [checked]="data.secondLevel.selected.isSelected(second)">
                                        </mat-checkbox>
                                    </ng-template>
                                </ng-container>
                                <ng-template #validationField>
                                    <app-validations-display [data]="second" [parent]="data"></app-validations-display>
                                </ng-template>
                                <ng-template #otherFields>
                                    <ng-container
                                        *ngIf="gridData.rowModify && gridData.rowModify.save; else notRowModify">
                                        <ng-container
                                            *ngIf="data.editMode && second.addButtonField && header.name === 'startDate'; else regularDisplay">
                                            <span class="add-button"
                                                (click)="addDateRange(data, data.eSecondData, second);$event.stopPropagation();">+
                                                ADD DATE RANGE</span>
                                        </ng-container>
                                        <ng-template #regularDisplay>

                                            <span
                                                [ngClass]="{'negative': header.name === 'variance'&& (second[header.name] < 0)}"
                                                *ngIf="!data.editMode || (data.editMode && !header.editable && !header.editableSecondLevel && !header.hasDelete)"
                                                [matTooltip]="isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]"
                                                class="text-span">{{isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]}}</span>
                                            <app-dynamic
                                                *ngIf="data.editMode && header.editable && header.editableSecondLevel && !second.addButtonField"
                                                [data]="second" [parent]="data" [field]="header"
                                                (dynamicCompChange)="dynamicCompChangeEvent($event)"
                                                (dynamicCompInputFieldBlur)="dynamicCompInputFieldBlur($event)">
                                            </app-dynamic>
                                        </ng-template>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notRowModify>
                                    <ng-container
                                        *ngIf="(header.name === 'invoiceId' || header.name === 'selectedWbse' || header.name === 'invoiceNo'); else nonLinkFields">
                                        <span *ngIf="(header.name === 'invoiceId' || header.name ==='invoiceNo')" class="text-span"><a
                                                class="linkField"
                                                (click)="openModal(second);$event.stopPropagation();">{{second[header.name]}}</a></span>
                                        <div *ngIf="(header.name === 'selectedWbse')" class="mat-select">
                                            <mat-select *ngIf="second['viewType'] === 'M'; else notMediaView"
                                                [ngClass]="{'error-border': second.wbsElementValidation || second.wbselementWarning || !second[header.name]}"
                                                disableOptionCentering #wbsElementSelectElem
                                                [(value)]="second[header.name]"
                                                (selectionChange)="onSelectionChange(second,$event,header.name, data)"
                                                [displaySelectboxAroundInput]="wbsElementSelectElem"
                                                panelClass="media-form-select-panel" required>
                                                <mat-option *ngFor="let option of second['wbsElement']"
                                                    [value]="option">
                                                    {{option}}
                                                </mat-option>
                                            </mat-select>
                                            <ng-template #notMediaView>
                                                <span class="text-span">{{second[header.name]}}</span>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <ng-template #nonLinkFields>
                                        <ng-container
                                            *ngIf="!(header.name === 'estimate' && second['isEditable']); else estimateField">
                                            <span
                                                [matTooltip]="isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]"
                                                [ngClass]="{'negative': (header.name === 'variance'&& (second[header.name] < 0)), 'error-border': header.name === 'glAccountNumber' && second.glaccountWarning}"
                                                class="text-span">{{isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]}}</span>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                                <ng-template #estimateField>
                                    <app-edit-estimate [header]="header" [current]="second" [parent]="data"
                                        [showTooltip]="data['genreNo'] && second['showId']" [levelIndex]="1"
                                        (emitEditted)="getEdittedValue($event)" (hidenResetFilter)="hideFilter($event)">
                                    </app-edit-estimate>
                                </ng-template>
                            </div>
                        </mat-cell>
                    </ng-container>
                </ng-container>
                <ng-template #sameTable>
                    <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!header.sort"
                            fxFlex="{{header.width}}">{{header.label}}</mat-header-cell>
                        <mat-cell *matCellDef="let second;let rowIndex = index" fxFlex="{{header.width}}"
                            [ngClass]="{'editEstimateInline' : header.name === 'estimate' && second['isEditable'] && second['isEdited']}"
                            (click)="$event.stopPropagation();">
                            <div fxFlex="100" [ngClass]="{'grouping-row-expansionColumn': header.name !== 'estimate'}">
                                <span class="float-left flexAlignIcon">
                                    <mat-icon class="grid_arrows"
                                        *ngIf="second?.showTree[header.name] && !second?.thirdLevel?.displayResults"
                                        (click)="toggleList(second, 'thirdLevel', 'eThirdData', data);$event.stopPropagation();">
                                        arrow_right</mat-icon>
                                </span>
                                <span class="float-left flexAlignIcon">
                                    <mat-icon class="grid_arrows"
                                        *ngIf="second?.showTree[header.name] && second?.thirdLevel?.displayResults"
                                        (click)="toggleList(second, 'thirdLevel', 'eThirdData', data);$event.stopPropagation();">
                                        arrow_drop_down</mat-icon>
                                </span>
                                <ng-container
                                    *ngIf="gridData.rowModify && gridData.rowModify.save && second.status !== 'Accrued'; else notRowModify">
                                    <ng-container
                                        *ngIf="data.editMode && second.addButtonField && header.name === 'startDate'; else regularDisplay">
                                        <span class="add-button"
                                            (click)="addDateRange(data, data.eSecondData, second);$event.stopPropagation();">+
                                            ADD DATE RANGE</span>
                                    </ng-container>
                                    <ng-template #regularDisplay>
                                        <button mat-icon-button
                                            *ngIf="data.editMode && header.hasDelete && second.hasDelete">
                                            <mat-icon class="icon-blue" (click)="deleteCurrentRow(second, data)">delete
                                            </mat-icon>
                                        </button>
                                        <span
                                            [matTooltip]="isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]"
                                            [ngClass]="{'negative': header.name === 'variance'&& (data[header.name] < 0)}"
                                            *ngIf="!data.editMode || (data.editMode && !header.editable && !header.editableSecondLevel)"
                                            class="text-span">{{isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]}}</span>
                                        <app-dynamic
                                            *ngIf="data.editMode && header.editable && header.editableSecondLevel && !second.addButtonField"
                                            [data]="second" [parent]="data" [field]="header"
                                            (dynamicCompChange)="dynamicCompChangeEvent($event)"
                                            (dynamicCompInputFieldBlur)="dynamicCompInputFieldBlur($event)">
                                        </app-dynamic>
                                    </ng-template>
                                </ng-container>
                                <ng-template #notRowModify>
                                    <ng-container
                                        *ngIf="!(header.name === 'estimate' && second['isEditable']); else estimateField">
                                        <ng-container *ngIf="header.name !== 'status'; else statusField">
                                            <span
                                                [matTooltip]="isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]"
                                                [ngClass]="{'negative': header.name === 'variance'&& (second[header.name] < 0)}"
                                                class="text-span">{{isAmount(header.name,second[header.name])? (zeroIfNegative(second,header) | number: numberFormat | minusSignToParens): second[header.name]}}</span>
                                        </ng-container>
                                        <ng-template #statusField>
                                            <span class="text-span"></span>
                                        </ng-template>
                                    </ng-container>
                                </ng-template>
                                <ng-template #estimateField>
                                    <app-edit-estimate [header]="header" [current]="second" [parent]="data"
                                        [showTooltip]="data['genreNo'] && second['showId']" [levelIndex]="1"
                                        (emitEditted)="getEdittedValue($event)" (hidenResetFilter)="hideFilter($event)">
                                    </app-edit-estimate>
                                </ng-template>
                            </div>
                        </mat-cell>
                    </ng-container>
                </ng-template>
                <ng-container *ngIf="displayNewTableLevel2 else sameTableRow">
                    <mat-row *matRowDef="let second; columns: displayedColumns2;"
                        [cdkDetailRowDisplayTpl]="second.eThirdData?.length && second?.thirdLevel?.displayResults"
                        [cdkDetailRow]="second" [cdkDetailRowTpl]="thirdLevelListingTemplate">
                    </mat-row>
                </ng-container>
                <ng-template #sameTableRow>
                    <mat-row *matRowDef="let second; columns: displayedColumns;"
                        [cdkDetailRowDisplayTpl]="second.eThirdData?.length && second?.thirdLevel?.displayResults"
                        [cdkDetailRow]="second" [cdkDetailRowTpl]="thirdLevelListingTemplate">
                    </mat-row>
                </ng-template>
            </mat-table>
        </div>
    </ng-template>
    <ng-template #thirdLevelListingTemplate let-second>
        <div [@detailExpandThird]="second.thirdLevel.displayResults ? 'open' : 'closed'" style="overflow: hidden">
            <mat-table [dataSource]="second.thirdLevel.dataSource" matSort #secondData="matSort"
                [ngStyle]="{'background-color':'white'}">
                <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                    <mat-header-cell *matHeaderCellDef fxFlex="{{header.width}}">{{header.label}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let third" fxFlex="{{header.width}}"
                        [ngClass]="{'editEstimateInline' : header.name === 'estimate' && third['isEditable'] && third['isEdited']}"
                        class="grouping-row3" (click)="$event.stopPropagation();">
                        <div fxFlex="100" [ngClass]="{'grouping-row-expansionColumn': third?.showTree[header.name]}">
                            <span class="float-left flexAlignIcon">
                                <mat-icon class="grid_arrows"
                                    *ngIf="third?.showTree[header.name] && !third?.fourthLevel?.displayResults"
                                    (click)="toggleList(third, 'fourthLevel', 'eFourthData', second);$event.stopPropagation();">
                                    arrow_right</mat-icon>
                            </span>
                            <span class="float-left flexAlignIcon">
                                <mat-icon class="grid_arrows"
                                    *ngIf="third?.showTree[header.name] && third?.fourthLevel?.displayResults"
                                    (click)="toggleList(third, 'fourthLevel', 'eFourthData', second);$event.stopPropagation();">
                                    arrow_drop_down</mat-icon>
                            </span>
                            <ng-container
                                *ngIf="header.name === 'estimate' && third['isEditable']; else genericField">
                                <app-edit-estimate [header]="header" [current]="third" [parent]="second"
                                    [showTooltip]="false" [levelIndex]="2" (hidenResetFilter)="hideFilter($event)"
                                    (emitEditted)="getEdittedValue($event)">
                                </app-edit-estimate>
                            </ng-container>
                            <ng-template #genericField>
                                <span
                                  [matTooltip]="isAmount(header.name,third[header.name])? (zeroIfNegative(third,header) | number: numberFormat | minusSignToParens): third[header.name]"
                                  [ngClass]="{'negative': header.name === 'variance'&& (third[header.name] < 0)}"
                                    class="text-span">{{isAmount(header.name,third[header.name])? (zeroIfNegative(third,header) | number: numberFormat | minusSignToParens): third[header.name]}}</span>
                            </ng-template>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-row *matRowDef="let third; columns: displayedColumns;"
                    [cdkDetailRowDisplayTpl]="third.eFourthData?.length && third?.fourthLevel?.displayResults"
                    [cdkDetailRow]="third" [cdkDetailRowTpl]="fourthLevelListingTemplate">
                </mat-row>
            </mat-table>
        </div>
    </ng-template>
    <ng-template #fourthLevelListingTemplate let-third>
        <div [@detailExpandThird]="third.fourthLevel.displayResults ? 'open' : 'closed'" style="overflow: hidden">
            <mat-table [dataSource]="third.fourthLevel.dataSource" matSort #secondData="matSort"
                [ngStyle]="{'background-color':'white'}">
                <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                    <mat-header-cell *matHeaderCellDef fxFlex="{{header.width}}">{{header.label}}
                    </mat-header-cell>
                    <mat-cell
                        [ngClass]="{'isHiglighted': gridData.type === 'Drop Summary' && fourth['isHiglighted'] === 'Y' && fourth[header.name] !== ''}"
                        *matCellDef="let fourth" fxFlex="{{header.width}}" (click)="$event.stopPropagation();">
                        <div fxFlex="100" class="fourthLevelContent-wrapper">
                            <mat-checkbox *ngIf="gridData.type === 'Drop Summary' && header.name === 'ioID'"
                                class="highlight-checkbox" [checked]="fourth['isHiglighted'] === 'Y'"
                                (click)="toggleHighlight(fourth); $event.stopPropagation(); $event.preventDefault();">
                            </mat-checkbox>

                            <span class="text-span"
                                [matTooltip]="isAmount(header.name,fourth[header.name])? (zeroIfNegative(fourth,header) | number: numberFormat | minusSignToParens): fourth[header.name]"
                                [ngClass]="{'checkboxIO-id': gridData.type === 'Drop Summary' && header.name === 'ioID'}">{{isAmount(header.name,fourth[header.name])? (zeroIfNegative(fourth,header) | number: numberFormat | minusSignToParens): fourth[header.name]}}</span>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-row *matRowDef="let fourth; columns: displayedColumns;">
                </mat-row>
            </mat-table>
        </div>
    </ng-template>
    <div class="no-records-wrapper" *ngIf="showNoMsg">
        <div class="no-records-found">
            <h4>{{gridData.noDataMsg}}</h4>
        </div>
    </div>
    <mat-paginator [ngClass]="{'hidePagination' : !gridData.pagination}" [pageSize]="displayPageSize"
        [pageSizeOptions]="displayPageSizeOptions">
    </mat-paginator>
</div>
<ng-template #errorMessage>
    <div class="parentEstimateWarning">
        <div class="div-warning-icon">
            <mat-icon class="warning-icon">warning</mat-icon>
        </div>
        <div class="estimateWarning">Changing the show level estimate will override the associated media type estimates
            to
            the default percentages</div>
    </div>
</ng-template>

<ng-template #versionMetaData>
    <div class="versionMetaData" *ngFor="let v of gridData.info.versionToolTipInfo">
        <div class="periodHeader">{{v.estimatePeriod}}</div>
        <div>Version {{v.latestVersion}} , updated by {{ v.lastUpdatedBy }} on {{v.lastUpdatedDate}}</div>
    </div>
</ng-template>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-index="index">
        <input class="mat-menu-filter" matInput #filterInput placeholder="Search" autocomplete="off" (click)="$event.stopPropagation()"
            (keyup)="filterSelectItems(index,$event)"/>
        <mat-checkbox [checked]="item.checked" role="menuitemcheckbox" class="mat-menu-item-Override"
            (click)="filterChange(index,item,$event,filterInput)" *ngFor="let item of filterSelectObj[index].filteredOptions">
            {{isAmount(filterSelectObj[index].name,item.name)? (item.name | number:'1.2-2' | minusSignToParens): item.name}}
        </mat-checkbox>
    </ng-template>
</mat-menu>
<mat-menu #accrualsMenu="matMenu" yPosition="below">
    <span *ngFor="let item of menuItems">
        <button mat-menu-item (click)="menuSelection(item)">{{item.label}}</button>
    </span>
</mat-menu>
