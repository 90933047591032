import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss']
})
export class AppSpinnerComponent implements OnInit, OnChanges {
  @Input() loadingIcon;
  constructor() { }

  ngOnInit(): void {
    this.setDisable();
  }

  ngOnChanges() {
    this.setDisable();
  }

  setDisable() {
    document.body.style['pointer-events'] = this.loadingIcon ? 'none' : 'all';
  }

}
