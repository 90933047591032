import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import {CognitoIdentity, Credentials} from 'aws-sdk';
import { retry } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthService {
    public httpOptions: any;
    public loggedInValidation ="";
    constructor(private httpClient: HttpClient) {
        this.httpOptions = {
            headers: new HttpHeaders().
              set('content-type', 'application/json').
              set('Accept', 'application/json').
              set('Access-Control-Allow-Origin', '*')
          };
    }
    logoutUser(){
        localStorage.clear();
        window.location.href= environment.logoutURL+environment.appURL;
    }
}
