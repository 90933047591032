import { AbstractControl, ValidatorFn } from '@angular/forms';

export function currencyCommaSeperatedValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '') {
      return null;
    }

    const currencyPattern = /^(\d{1,3}(,\d{3})*(\.\d{2})?)(,(\d{1,3}(,\d{3})*(\.\d{2})?))*$/;
    if (!currencyPattern.test(control.value)) {
      const val = control.value;
      let valAsNumber = null;
      if (val !== null && val !== undefined && val !== '' && typeof val === 'string') {
        valAsNumber = Number((val.replace(/,/g, '')));
      }
      if (valAsNumber) {
        return null;
      } else {
        return { currencyCommaSeperated: { value: control.value } };
      }
    }
    return null;
  };
}
