<ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'input'">
        <input matInput type="text" [value]="data[field.name]" [(ngModel)]="data[field.name]"
            (ngModelChange)="modify(data, parent, $event)"
            (blur)="onBlur(data, parent); $event.stopPropagation(); $event.preventDefault();"
            [ngClass]="{'error-border': data.glaccountWarning || data.wbselementWarning}">
    </ng-container>
    <ng-container *ngSwitchCase="'amountFieldEditable'">
        <app-amount-field-editable [data]="data" [parent]="parent" [field]="field"
            (amountFieldChange)="amountFieldChangeEvent($event)"></app-amount-field-editable>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
        <div class="select-wrapper" [ngClass]="{'error-border' : field.name === 'mediaSubType' && !selectedMediaSubType}">
            <ng-container *ngIf="field.name === 'mediaType'">
                <mat-select disableOptionCentering #mediaTypeSelectElem
                [displaySelectboxAroundInput]="mediaTypeSelectElem" panelClass="media-form-select-panel"
                [(value)]="selectedMediaType" (selectionChange)="onSelectionChange(data, parent, $event)">
                <mat-option *ngFor="let option of mediaTypes" [value]="option">
                  {{option}}
                </mat-option>
              </mat-select>
            </ng-container>
            <ng-container *ngIf="field.name === 'mediaSubType'">
              <span>
                <mat-select disableOptionCentering #mediaSubTypeSelectElem
                    [displaySelectboxAroundInput]="mediaSubTypeSelectElem" panelClass="media-form-select-panel"
                    [(value)]="selectedMediaSubType" (selectionChange)="onSelectionChange(data, parent, $event)">
                    <mat-option *ngFor="let option of mediaSubTypes" [value]="option" required>
                        {{option}}
                    </mat-option>
                </mat-select>
              </span>
            </ng-container>
            <ng-container *ngIf="field.name === 'showName'">
                <mat-select disableOptionCentering #showSelectElem [displaySelectboxAroundInput]="showSelectElem"
                    panelClass="media-form-select-panel" [(value)]="selectedShowName"
                    (selectionChange)="onSelectionChange(data, parent, $event)">
                    <mat-option *ngFor="let option of showNames" [value]="option.showName">
                        {{option.showName}}
                    </mat-option>
                </mat-select>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
        <div class="date-picker">
            <span class="date-picker-wrapper" *ngIf="field.name === 'startDate'" [ngClass]="{'error-border' : validationFlag}">
                <input matInput [matDatepicker]="picker" [(ngModel)]="data[field.name]"
                    (ngModelChange)="modify(data, parent, $event)" required [value]="dateFormatted" placeholder="mm/dd/yyyy" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </span>
            <span class="date-picker-wrapper" *ngIf="field.name === 'endDate'" [ngClass]="{'error-border' : validationFlag}">
                <input matInput [matDatepicker]="picker" [(ngModel)]="data[field.name]" required
                    [min]="data.startDate === '' ? '' : data.startDate" (ngModelChange)="modify(data, parent, $event)"
                    [value]="dateFormatted" placeholder="mm/dd/yyyy" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </span>
            <span *ngIf="field.name === 'endDate' && data.dateRangeWarning === true" class="div-warning-icon">
                <mat-icon class="warning-icon" [tooltip]="validations" content-type="template" placement="top"
                    show-delay="100" hide-delay="100">warning</mat-icon>
            </span>
        </div>
    </ng-container>
</ng-container>
<ng-template #validations>
    <div class="validations" *ngFor="let validation of data.validations">
        <div>{{validation}}</div>
    </div>
</ng-template>
