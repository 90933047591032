<div #notesWrapper notesSizing class="notesWrapper" [ngClass]="{ 'io-height': data?.notesHeight === 39 }">
    <div #notesDisplayFieldWrapper class="notes-displayField-wrapper" [ngClass]="{ 'hide': rowEdit }"
        [style.height.px]="data?.notesHeight ? data.notesHeight : 40" [style.width.%]="header.width">
        <div *ngIf="inlineSave" class="material-icons edit-icon"
            (click)="notesEditableMode($event, notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">edit</div>
        <div #notesOriginal class="notes-displayField"
            [style.height.px]="data?.notesHeight ? data.notesHeight - 8 : 25">{{data[header.name]}}</div>
    </div>
    <div #notesEditable class="notesEditable" [ngClass]="{ 'show': rowEdit }"
        [style.height.px]="data?.notesHeight ? data.notesHeight : 40">
        <div *ngIf="inlineSave" class="notes-edit-actions">
            <div class="material-icons cancel-icon"
                (click)="modifyNotes(data, 'removeCopy', notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">close</div>
            <div class="material-icons save-icon"
                (click)="modifyNotes(data, 'save', notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">check</div>
        </div>
        <textarea rows="3" matInput
            [style.height.px]="data?.notesHeight ? data.notesHeight - editIconHeight : defaultEditableHeight"
            [(ngModel)]="data[header.name]"
            (ngModelChange)="modifyNotes(data, 'modifyCopy', notesDisplayFieldWrapper, notesEditable, $event)"
            (focus)="focusNotesField($event);$event.stopPropagation();"
            (click)="$event.stopPropagation();">{{data[header.name]}}</textarea>
    </div>
</div>
