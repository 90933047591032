import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { EstimatesService } from 'src/app/services/estimates.service';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceService } from '../../../services/invoice.service';
import { IOService } from 'src/app/services/io.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  public wrongExcelFormat; public wrongPdfFormat;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  @Input() fileType: any; @Input() directSubmit: any; @Input() resetUpload: any;
  @Output() importResponse: EventEmitter<any> = new EventEmitter();
  @Output() outputFiles: EventEmitter<any> = new EventEmitter();
  files: any[] = [];
  fileUploadForm: UntypedFormGroup;
  fileInputLabel: string; fileList = []; nameList = [];
  faFileExcel = faFileExcel;
  faFilePdf = faFilePdf;
  showFileName = false; showPdfFileName = false;  disableBtn;
  loadingIcon = false;
  @Input() importType: string;

  constructor(private http: HttpClient,
              private formBuilder: UntypedFormBuilder,
              private eS: EstimatesService,
              private iS: InvoiceService,
              private ios: IOService,
              public dialog: MatDialog) {
                this.iS.disableListBtn.subscribe(res => this.disableBtn = res);
                this.iS.resetFiles.subscribe(res => {
                  if (res) {
                    this.showFileName = false; this.fileList = [];
                    this.showPdfFileName = false; this.nameList = [];
                    this.fileUploadForm = this.formBuilder.group({
                      myfile: ['']
                    });
                    this.iS.showCancel.next(false);
                  }
                });
              }

  ngOnInit(): void {
    // console.log('type', this.fileType);
    this.wrongExcelFormat = false;
    this.fileUploadForm = this.formBuilder.group({
      myfile: ['']
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.importType){
      this.wrongExcelFormat = false;
      if(this.uploadFileInput && this.uploadFileInput.nativeElement){
        this.uploadFileInput.nativeElement.value= '';
      }
    }
  }

  onFileDropped(files) {
    if (files) {
      const excel = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
      const pdf = ['application/pdf'];
      if (files.length > 0) {
        const file = files[0];
        if (_.includes(excel, file.type)) {
          this.wrongExcelFormat = false;
          this.fileInputLabel = file.name;
          this.fileUploadForm.get('myfile').setValue(file);
          this.onFormSubmit();

          // alert('Only EXCEL Docs Allowed!');
        }
        else if (_.includes(pdf, file.type)) {
          if (files.length > 0) {
            const fileArr = [];
            //const files = files;
            Array.from(files).forEach((file, index) => {
              const fileContent = files[index];
              if (_.includes(pdf, fileContent.type)) {
                fileArr.push(fileContent);
                this.nameList.push(fileContent.name);
              }
            });
            if (fileArr.length <= 0) {
              this.wrongPdfFormat = true;
              // alert('Only pdf Docs Allowed!');
            } else {
              this.wrongPdfFormat = false;
              this.fileList = [...fileArr];
              this.fileUploadForm.get('myfile').setValue(fileArr);
              this.onFormSubmit();
            }
          }

          // alert('Only EXCEL Docs Allowed!');
        }
        else {
          this.wrongExcelFormat = true;
          this.wrongPdfFormat = true;
          this.loadingIcon = false;

        }
      }
    }
  }

  onFileSelect(event, type?) {
    const excel = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    const pdf = ['application/pdf'];
    if (!type) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        // console.log(file);
        if (!_.includes(excel, file.type)) {
          this.wrongExcelFormat = true;
          this.loadingIcon = false;
          // alert('Only EXCEL Docs Allowed!');
        } else {
          this.wrongExcelFormat = false;
          this.fileInputLabel = file.name;
          this.fileUploadForm.get('myfile').setValue(file);
          this.onFormSubmit();
        }
      }
    } else if (type === 'pdf') {
      if (event.target.files.length > 0) {
        const fileArr = [];
        const files = event.target.files;
        Array.from(files).forEach((file, index) => {
          const fileContent = event.target.files[index];
          if (_.includes(pdf, fileContent.type)) {
            fileArr.push(fileContent);
            this.nameList.push(fileContent.name);
          }
        });
        if (fileArr.length <= 0) {
          this.wrongPdfFormat = true;
          // alert('Only pdf Docs Allowed!');
        } else {
          this.wrongPdfFormat = false;
          this.fileList = [...fileArr];
          this.fileUploadForm.get('myfile').setValue(fileArr);
          this.onFormSubmit();
        }
      }
    }
  }

  addFiles(event, type) {
    const pdf = ['application/pdf'];
    if (event.target.files.length > 0) {
      const addFileArr = []; const files = event.target.files;
      Array.from(files).forEach((file, index) => {
        const fileContent = event.target.files[index];
        if (_.includes(pdf, fileContent.type)) {
          addFileArr.push(fileContent);
          this.fileList.push(fileContent);
          this.nameList.push(fileContent.name);
        }
      });
      if (addFileArr.length <= 0) {
        this.wrongPdfFormat = true;
      } else {
        this.wrongPdfFormat = false;
        this.fileUploadForm.get('myfile').setValue(addFileArr);
        this.onFormSubmit();
      }
    }
  }

  openFileList(){
    let openList = true;
    this.outputFiles.emit({ file: this.fileType, openList, list: this.fileList, pdfNames: this.nameList});
    openList = false;
  }

  onFormSubmit() {
    if (!this.fileUploadForm.get('myfile').value) {
      return false;
    }
    const formData = new FormData();
    formData.append('file', this.fileUploadForm.get('myfile').value);
    formData.append('fileName', this.fileInputLabel);
    if (this.directSubmit) {
      this.loadingIcon = true;
      switch(this.importType){
        case 'estimates' : this.importEstimates(formData); break;
        case 'io': this.importIO(formData); break;
        case 'updateio': this.importIOUpdate(formData); break;
      }
    } else {
      if (this.fileType === 'excel') {
        this.showFileName = true;
        this.outputFiles.emit({
          fileData: this.fileUploadForm.get('myfile').value,
          file: this.fileType,
        });
      } else if (this.fileType === 'pdf') {
        this.showPdfFileName = true;
        this.nameList.length <= 0
          ? this.iS.disableListBtn.next(true)
          : this.iS.disableListBtn.next(false);
        this.outputFiles.emit({
          file: this.fileType,
          list: this.fileList,
          pdfNames: this.nameList,
        });
      }
    }
  }

  importEstimates(formData){
    this.eS.importEstimates(formData).subscribe(response => {
      if (response) {
        this.loadingIcon = false;
        this.importResponse.emit(response);
        // Reset the file input
        this.wrongExcelFormat = false;
        this.uploadFileInput.nativeElement.value = '';
        this.fileInputLabel = undefined;
      }
    }, error => {
      this.wrongExcelFormat = true;
      this.loadingIcon = false;
      console.log(error);
    });
  }

  importIO(formData){
    let uploadResponse = {
      type: 'add',
      resp: null
    }
    this.ios.importIO(formData).subscribe(response => {
      if (response && response.length) {
        this.loadingIcon = false;
        uploadResponse.resp=response;
        this.importResponse.emit(uploadResponse);
        // Reset the file input
        this.wrongExcelFormat = false;
        this.uploadFileInput.nativeElement.value = '';
        this.fileInputLabel = undefined;
      }
      else
      {
        this.wrongExcelFormat = true;
        this.loadingIcon = false;
      }
    }, error => {
      this.wrongExcelFormat = true;
      this.loadingIcon = false;
      console.log(error);
    });
  }

  importIOUpdate(formData){
    let uploadResponse = {
      type: 'update',
      resp: null
    }
    this.ios.importIOUpdate(formData).subscribe(response => {
      if (response && response.length) {
        this.loadingIcon = false;
        uploadResponse.resp=response;
        this.importResponse.emit(uploadResponse);
        // Reset the file input
        this.wrongExcelFormat = false;
        this.uploadFileInput.nativeElement.value = '';
        this.fileInputLabel = undefined;
      }
      else
      {
        this.wrongExcelFormat = true;
        this.loadingIcon = false;
      }
    }, error => {
      this.wrongExcelFormat = true;
      this.loadingIcon = false;
      console.log(error);
    });
  }
}
