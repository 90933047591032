<div class="form-container">
  <form class="justify-center" [formGroup]="form">
    <div class="container-editable-field">
      <div class="input-container">
        <input matInput #input
               [readonly]="!isControlEdit || isClosed"
               type="text"
               [ngModel]="value | number:'1.0-2'"
               [ngClass]="{'error': form.get('val').hasError('currencyCommaSeperated'), 'closed': isClosed}"
               (ngModelChange)="handleModelChange($event)"
               formControlName="val" tabindex="0" class="input-field" (change)="_validate()"
        />
      </div>
    </div>
  </form>
</div>

