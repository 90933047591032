import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../services/common.service';
import { IEstimatesDetail } from '../models/estimates.model';
import { environment } from '../../environments/environment';
import { EstimatesConstants } from '../media-management/estimates/estimates.constants';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstimatesService {
  @ViewChild('outputElem') public outputElem: ElementRef;
  public stopProgressLoader   = new BehaviorSubject(false);
  constructor(private http: HttpClient, private cS: CommonService) {
  }

  fetchEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.getEstimates;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  fetchAddMasterData() {
    const url = environment.basePath + EstimatesConstants.apiUrl.masterDataEstimates;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchEstimatesVersionDetails(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.getEstimatesVersionDetails;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.updateEstimates;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  saveEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.saveEstimates;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  saveNotes(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.saveNotes;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  fetchVersion(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.estimateVersion;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  importEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.importEstimates;
    return this.cS.post(url, payLoad);
  }

  saveImportEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.saveImportEstimates;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  validateEstimates(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.addEstimateValidation;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  addEstimates(payload: IEstimatesDetail[]) {
    return;
  }

  fetchioAsOfDates(payLoad) {
    const url = environment.campaignBasePath + EstimatesConstants.apiUrl.ioIssuedDates;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }
  fetchEstimateDistribution() {
    const url = environment.basePath + EstimatesConstants.apiUrl.estimateDistribution;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchEstimatesTemplate() {
    const url = environment.basePath + EstimatesConstants.apiUrl.downloadTemplate;
    this.cS.downloadTemplate(url).subscribe((res: HttpResponse<any>) => {
      const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
      const a = document.createElement('a');
      const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      a.href = URL.createObjectURL(file);
      a.download = filename;
      a.click();
      // this.outputElem.nativeElement.click();
      this.stopProgressLoader.next(true);
    }, err => {
        this.stopProgressLoader.next(true);
    });
  }

  exportHandlerService(payLoad) {
    const url = environment.basePath + EstimatesConstants.apiUrl.estimateExport;
    this.cS.fileDownload(url, payLoad).subscribe((res: HttpResponse<any>) => {
      const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');

      const a = document.createElement('a');
      const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      a.href = URL.createObjectURL(file);
      a.download = filename;
      a.click();
      // this.outputElem.nativeElement.click();
      this.stopProgressLoader.next(true);
    }, err => {
        this.stopProgressLoader.next(true);
    });

  }
}
