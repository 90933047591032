import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppJsonDataService } from './services/app-json.service';
import { AppSingletonService } from './services/app-singleton.service';
import { environment } from 'src/environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { WarningDialogComponent } from './shared/components/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthManagerService } from './services/auth/authManager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'media-management';
  constructor(private titleService: Title, private aJDS: AppJsonDataService, private aSS: AppSingletonService,
              private idle: Idle, public dialog: MatDialog, private authManagerService: AuthManagerService,
              private router: Router) {
    console.log('environmentName : ', environment.environmentName);
    this.setTitle(environment.environmentName);
    this.checkIdleTime();
    if(localStorage.getItem('paceUserData')){
      let userData = JSON.parse(localStorage.getItem('paceUserData'));
      this.removeUnAuthorizedPaths(userData);
    }

  }

  private setTitle(newTitle: string) {
    newTitle = `${newTitle} - Media Management`;
    this.titleService.setTitle(newTitle);
  }
  checkIdleTime() {
    this.idle.setIdle(60);
    this.idle.setTimeout(7140);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        height: '160px',
        data: { name: 'timeout', title: 'Timeout'},
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(value => {
        this.authManagerService.relogin();
      });
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });
    this.reset();
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit() {
   this.getJsonStore();
   newrelic.setCustomAttribute('UserSSO',localStorage.getItem('paceUserSSO'));
  }

  removeUnAuthorizedPaths(res){
    if(res.userTabs && res.userTabs.length > 0){
      this.router.config = this.router.config.filter(obj => !obj.path || res.userTabs.indexOf(obj.path) > -1 || obj.path.indexOf('error') > -1);
      if(res.defaultModuleName && res.userTabs.indexOf(res.defaultModuleName.toLowerCase()) >-1){
        this.router.config[0].redirectTo = res.defaultModuleName.toLowerCase();
      }
      else{
        this.router.config[0].redirectTo = res.userTabs[0];
      }
    }
    else{
      this.router.config = this.router.config.filter(obj => obj.path.indexOf('error') > -1);
    }
  }

  getJsonStore = () => {
    this.aJDS.getMediaStoreJson().subscribe(
      (data: any) => {
        this.aSS.mediaJsonStore = data;
        this.aJDS.mediaStoreSubject.next();
        // set value to true once we get the data from mediastore.
        this.aSS.mediaJsonStoreCheck = true;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
