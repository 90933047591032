<section [ngSwitch]="content"
    [ngClass]="{'file-upload': content === 'pdf-list', 'warning-dialog': content === 'ioData' ||  content === 'timeout',
            'import': content === 'pdf-list' || content === 'import','popup': content === 'cancel' || content === 'sso' || content === 'media' || content === 'addSeries' ||  content === 'addSeasonType' || content === 'addMediaType' || content === 'decline' || content === 'saveAccrual' || content === 'closeAccrual' || content === 'deleteIOs' || content === 'editNotes' || content === 'updatedios',
        'no-msg': content === 'media','glAccValidation': content === 'invoiceErrorPopup' }">
    <h1 mat-dialog-title class="row" *ngIf="content !== 'postDoc' && content !== 'reverseDoc' && content !== 'deleteIOs' && content !== 'multipleClose' || content === 'updatedios'">{{data.title}}
        <button disableRipple mat-icon-button class="close-button float-right" *ngIf="content !== 'closeAccrual'" [ngClass]="{'timeoutClose': content === 'timeout','glAccClose': content === 'invoiceErrorPopup'}" (click)="onCancel()" tabindex="-1">
            <mat-icon disableRipple>close</mat-icon>
        </button>
    </h1>
    <h1 mat-dialog-title class="row post-doc" *ngIf="content === 'postDoc' || content === 'reverseDoc'">{{data.title}}
        <div *ngIf="content === 'postDoc' || content === 'reverseDoc'">{{data.note + ' ' + data.docName}}.</div>
    </h1>
    <div *ngSwitchCase="'updatedios'" mat-dialog-content>
        <div class="dialog-content" *ngIf="data.successios">
            Update was successful for the following IO ids:
            <div>{{data.successios}}</div>
        </div>
        <div class="dialog-content" *ngIf="data.failedios">
            Update failed for the following IO ids:
            <div>{{data.failedios}}</div>
        </div>
        <div class="row float-right"><button mat-raised-button class="primary-small-btn float-right" (click)="onCancel()">OK</button></div>
    </div>
    <div *ngSwitchCase="'snapresponse'" mat-dialog-content>
      <div class="dialog-content">
          <div *ngFor="let item of data.invoices">
            <span>{{item.invoiceNo}}</span>
            <span>: {{item.message}}</span>
          </div>
      </div>
      <div class="row float-right"><button mat-raised-button class="primary-small-btn float-right" (click)="onCancel()">OK</button></div>
    </div>
    <div *ngSwitchCase="'deleteIO'" mat-dialog-content>
        <div class="dialog-content">
            You cannot delete a show that has issued IOs or has associated Estimates.
        </div>
        <div class="row float-right"><button mat-raised-button class="primary-small-btn float-right" (click)="onCancel()">OK</button></div>
    </div>
    <div *ngSwitchCase="'timeout'" mat-dialog-content>
      <div class="dialog-content timeoutText">
        Your application session has timed-out, Please re-login to continue.
      </div>
      <div class="row float-right"><button mat-raised-button class="primary-small-btn float-right" (click)="onCancel()">OK</button></div>
  </div>
    <div *ngSwitchCase="'import'" mat-dialog-content>
        <app-upload-file [fileType]="excel" [directSubmit]=true (importResponse)="handleResponse($event)"></app-upload-file>
    </div>
    <div *ngSwitchCase="'cancel'" mat-dialog-content>
        <div class="dialog-content">Are you sure you want to cancel the file upload?
        </div>
        <div class="row center">
            <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="reset()">YES</button>
            <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">NO</button>
        </div>
    </div>

    <div *ngSwitchCase="'deleteFlight'" mat-dialog-content>
      <div class="dialog-content">This delete action will remove flight allocation for all months.Do you want to continue?
      </div>
      <div class="row center">
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onDelete(true)" >YES</button>
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onDelete(false)">NO</button>
      </div>
    </div>
    <div *ngSwitchCase="'deleteInvoice'" mat-dialog-content>
      <div class="dialog-content">This delete action will permanently remove this invoice record.Do you want to continue?
      </div>
      <div class="row center">
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onDelete(true)" >YES</button>
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onDelete(false)">NO</button>
      </div>
    </div>

    <div *ngSwitchCase="'deleteIOs'" mat-dialog-content>
      <div class="dialog-content">Deleting the row will delete the IO record.Do you want to continue?
      </div>
      <div class="row center">
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="doYesAction(true)">YES</button>
        <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">NO</button>
      </div>
    </div>
    <div *ngSwitchCase="'closeAccrual'" mat-dialog-content>
        <div class="dialog-content closeAccrualConfirmationText"><span>Select versions to close below</span>
            <button disableRipple mat-icon-button class="close-button float-right" (click)="onCancel()" tabindex="-1">
                <mat-icon disableRipple>close</mat-icon>
            </button>
        </div>
        <div>
          <!-- <div *ngFor="let version of data.version; let i = index">
            <input type="checkbox" [(ngModel)]="version.isChecked" (change)="changeSelection(data)">   {{version.versionName}}
          </div> -->
        <div class="closeAccrualNote">*Note: Once a month is closed in PACE against a specific version, Media users will no
            longer be able :

            <ul>
                <li> Add/edit Flight up to the closed month </li>

            </ul>
        </div>
        <div class="closeVersionContainer">
          <app-common-grid [gridData]="gridDataDetails" (emitSelectedRows)="changeSelection($event)" *ngIf="gridDataDetails.data">
          </app-common-grid>
        </div>
          <div class="row center ptb-15">
            <button mat-raised-button disableRipple class="primary-small-btn float-right"
                (click)="onCancel()">CANCEL</button>
            <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!checkedIDs.length"
                (click)="closeAccrual()">CLOSE</button>
          </div>
      </div>
    </div>
    <div *ngSwitchCase="'multipleClose'" mat-dialog-content>
        <div class="dialog-content multipleCloseConfirmationText"><span>Are you sure multiple versions should be marked as Closed? </span>
        </div>
        <div class="row center pt-15">
            <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">CANCEL</button>
            <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="closeAccrual()">YES</button>
        </div>
    </div>
    <div *ngSwitchCase="'pdf-list'" mat-dialog-content>
        <div fxlayout="row" class="flex-container">
            <div *ngFor="let file of data.list; let i = index" class="uploaded-pdfs" fxFlex="50">
                <div *ngIf="i%2 === 0" class="mr-20">
                    <span>
                    <fa-icon [icon]="close" disableRipple (click)="deleteFile(file, i)"></fa-icon></span>
                    <a [href]="getFile(file)" download="{{file}}">{{file}}</a>
                </div>
                <div *ngIf="i%2 !== 0" class="ml-20">
                    <span><fa-icon [icon]="close" disableRipple (click)="deleteFile(file, i)"></fa-icon></span>
                    <a [href]="getFile(file)" download="{{file}}">{{file}}</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'invoiceErrorPopup'" mat-dialog-content>
        <div>
            <table fxFlex="100">
                <thead>
                    <tr>
                        <th fxFlex="15">Invoice No</th>
                        <th fxFlex="70">Error</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data.errorList">
                        <td fxFlex="15">{{ item.invoiceId }}</td>
                        <td fxFlex="70">{{ item.error }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row float-right glAccountCancel"><button mat-raised-button class="primary-small-btn float-right"
                (click)="onCancel()">Cancel</button></div>
    </div>
    <div *ngSwitchCase="'decline'" class="decline" mat-dialog-content>
             <mat-form-field class="text-area">
                <textarea  type="text" matInput [formControl]="declinecomment" maxlength="254"></textarea>
            </mat-form-field>
            <div class="row align-right">
                <!-- <a href="javascript:void(0);" class="" (click)="onDecline()">SKIP</a> -->
                <button mat-raised-button disableRipple class="secondary-small-btn skip-btn" (click)="onSkip()">SKIP</button>
                <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onDecline()">SUBMIT</button>
            </div>
    </div>
    <div *ngSwitchCase="'editNotes'" class="decline" mat-dialog-content>
        <mat-form-field class="text-area">
            <textarea type="text" matInput [(ngModel)]="data.comments"></textarea>
        </mat-form-field>
        <div class="row align-right">
            <!-- <a href="javascript:void(0);" class="" (click)="onDecline()">SKIP</a> -->
            <button mat-raised-button disableRipple class="primary-small-btn skip-btn"
                (click)="doYesAction(data.comments)">OK</button>
            <button mat-raised-button disableRipple class="primary-small-btn float-right"
                (click)="onCancel()">CANCEL</button>
        </div>
    </div>
    <div *ngSwitchCase="'updateShow'" class="decline" mat-dialog-content>
      <span  class="duplicateEstimate">{{data.errorMsg}}</span>
  </div>
    <div *ngSwitchCase="'addSeries'" mat-dialog-content>
        <form class="media-form add-input">
            <div>
                <mat-form-field appearance="outline">
                    <input matInput type="text" [formControl]="series" required>
                </mat-form-field>
                <span *ngIf="errorMsg" class="duplicateEstimate">{{errorMsg}}</span>
            </div>
            <div class="row center pt-15">
                <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!series" (click)="addField(content)">ADD</button>
                <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">CANCEL</button>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'addSeasonType'" mat-dialog-content>
        <form class="media-form add-input">
            <div>
                <mat-form-field appearance="outline">
                    <input matInput type="text" [formControl]="seasonType" required>
                    <!-- <mat-error *ngIf="seasonType.invalid">{{errorMsg}}</mat-error> -->
                </mat-form-field>
                <span *ngIf="errorMsg" class="duplicateEstimate">{{errorMsg}}</span>
                <div class="row center pt-15">
                    <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!seasonType" (click)="addField(content)">ADD</button>
                    <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">CANCEL</button>
                </div>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'addMediaType'" mat-dialog-content>
        <form class="media-form add-input">
            <div>
                <mat-form-field appearance="outline">
                    <input matInput type="text" [formControl]="mediaType" required>
                </mat-form-field>
                <span *ngIf="errorMsg" class="duplicateEstimate">{{errorMsg}}</span>
                <div class="row center pt-15">
                    <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!mediaType" (click)="addField(content)">ADD</button>
                    <button mat-raised-button disableRipple class="primary-small-btn float-right" (click)="onCancel()">CANCEL</button>
                </div>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'sso'" mat-dialog-content>
        <!-- <div class="dialog-content">User already exists</div> -->
    </div>
    <div *ngSwitchCase="'media'" mat-dialog-content>
        <!-- <div class="dialog-content">User already exists</div> -->
    </div>
    <div *ngSwitchCase="'postDoc'" mat-dialog-content>
        <form class="media-form post-doc add-input">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="100">
                    <div fxFlex="50">
                        <strong fxFlex="34">Posting Date: </strong>
                        <mat-form-field appearance="outline" fxFlex="60">
                            <input matInput [matDatepicker]="dp" [formControl]="postingDate">
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50">
                        <strong fxFlex="40">Posting Period: </strong>
                        <mat-form-field appearance="outline" fxFlex="60">
                          <input matInput type="text" appValidationFormat="month" placeholder="MM" [formControl]="postingPeriod" required>
                      </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="move-right">
                <a href="javascript:void(0);" class="cancelLink" (click)="onCancel()">CANCEL</a>
                <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!postingDate.valid || !postingPeriod.valid" (click)="postingInfo()">SUBMIT</button>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'reverseDoc'" mat-dialog-content>
        <form class="media-form post-doc add-input">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="100">
                    <strong fxFlex="20">Reversal Date:</strong>
                    <mat-form-field appearance="outline" fxFlex="25">
                        <input matInput [matDatepicker]="dp" [formControl]="postingDate">
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="move-right">
                <a href="javascript:void(0);" class="cancelLink" (click)="onCancel()">CANCEL</a>
                <button mat-raised-button disableRipple class="primary-small-btn float-right" [disabled]="!postingDate.valid"
                    (click)="postingInfo()">SUBMIT</button>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="'saveAccrual'" mat-dialog-content>
        <form class="media-form add-input">
            <div>
                <mat-label class="editVersionLabel">Edit Version Name</mat-label>
                <mat-form-field appearance="outline">
                    <!-- <label>Edit Version Name</label> -->
                    <input matInput type="text" #versionNamefield maxlength="50" [formControl]="versionName" required autocomplete="off">
                </mat-form-field>
                <span *ngIf="versionNameDuplicate" class="duplicateEstimate">Version name already exists</span>
            </div>
            <div class="row center pt-15" mat-dialog-actions>
                <button mat-raised-button disableRipple class="primary-small-btn float-right"
                    [disabled]="!versionNamefield.value" (click)="saveAccrual()">SUBMIT</button>
                <button mat-raised-button disableRipple class="primary-small-btn float-right"
                    (click)="onCancel()">CANCEL</button>
            </div>
        </form>
    </div>
</section>
