import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-validations-display',
  templateUrl: './validations-display.component.html',
  styleUrls: ['./validations-display.component.scss']
})
export class ValidationsDisplayComponent implements OnInit {
  @Input() data;
  @Input() parent;
  warningType: string;
  showAttachment = true;
  optionalAttachmentWarning ="This invoice is missing an attachement. You may continue and add an attachment later."

  constructor() { }

  ngOnInit(): void {
    this.showAttachment = this.checkOptionalAttachmentMessageForParentLevel();
    this.warningTypeCompute(this.data.validations);
  }

  ngDoCheck() {
    this.warningTypeCompute(this.data.validations);
  }

  warningTypeCompute(validations) {
    this.warningType = validations.length ? validations[0].type : '';
  }

  checkOptionalAttachmentMessageForParentLevel(): boolean {
    let showAttachmentIcon = true;
    if (this.parent && this.parent.validations && this.parent.validations.length > 0) {
      const hasAttachmentWarning = this.parent.validations.find(validation => validation.message === this.optionalAttachmentWarning);
      if (hasAttachmentWarning) {
        showAttachmentIcon = false;
      }
    }
    return showAttachmentIcon;
  }

  getValidationErrorTexts(validations){
      return `
       ${validations.map(item => `${item.message}`).join('<br>')}`;
  }
}
