import {Component, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { currencyCommaSeperatedValidator } from "./currency-comma-seperated-validator";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-editable-field-component',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss']
})
export class EditableFieldComponent implements OnInit, OnDestroy {


  constructor() {
  }
  editable = false;
  isValid = false;
  @Input() centerAlign = false;
  @Input() stackButtonsVertically = false; // For now let the developer set this, but in the future check boundaries
  @Input() editableByDefault = false;
  @Input() isControlEdit = true;
  @Input() needValidation = false;
  @Input() value = '';
  @Input() isClosed = false;
  @Input() compareValue = '';
  @Output() newValue = new EventEmitter();

  originalValue = '';
  form: FormGroup;
  showInputBox = true;

  ngOnInit() {
    this.form = new FormGroup<any>({
      // val: new FormControl(this.value, [Validators.required,
      //   Validators.minLength(1),
      //   currencyCommaSeperatedValidator(),
      // ]),
      val: new FormControl(this.value, {
        validators: [Validators.required, Validators.minLength(1), currencyCommaSeperatedValidator()],
        updateOn: 'change'
      })
    });

    // this.form.valueChanges.pipe(
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // ).subscribe((val) => {
    //   console.log('debouncing the value change');
    //   console.log(val)
    //   this._validate();
    // });
    this.originalValue = this.value; // keep it for the discard flow
    // if nothing is passed in, then treat it like a field ready to be edited. Otherwise
    // user wouldn't be able to edit
    // 0 is treated as an actual value
    if (this.originalValue === '') {
      this.editableByDefault = true;
    }
  }

  makeEditable() {
    if (this.isControlEdit) {
      this.editable = !this.editable;
    }
    else {
      this.editable = false;
    }
  }

  discard() {
    this.value = this.originalValue;
    if (this.originalValue !== '') {
      this.makeEditable(); // undo the changes
    }
  }

  submit() {
    this.form.clearValidators();
    if (this.needValidation) {
      this._validate();
    }
    if (this.isControlEdit) {
      this.editable = false;
    }
    if (this.form.valid) {
      this.newValue.emit(this.value);
    }
    if (!this.originalValue && this.value) {
      this.editableByDefault = false;
    }
  }

  ngOnDestroy() {
  }

  _validate() {
    if (!this.needValidation) {
      this.submit();
      return;
    }
    if (parseInt(this.value, 10) >= parseInt(this.compareValue, 10)) {
      this.isValid = true;
      this.newValue.emit(this.value);
      return;
    }
    this.isValid = false;
  }
  convertCommasSeperatedStringToNumber(value: string) {
    if (value !== null && value !== undefined && value !== '') {
      return parseFloat(value.replace(/,/g, ''));
    }
    return value;
  }
  handleModelChange($event) {
      this.value = this.convertCommasSeperatedStringToNumber($event).toString();
  }
}
