export const EstimatesConstants = {
    apiUrl: {
        getEstimates: 'estimates/search',
        masterDataEstimates: 'estimates/master-details',
        getEstimatesVersionDetails: 'estimates/versionMetaData',
        saveEstimates: 'estimates/',
        updateEstimates: 'estimates',
        saveNotes: 'estimates/comments',
        estimateVersion: 'estimates/version',
        addEstimateValidation: 'estimates/duplicateestimate-check',
        importEstimates: 'aspose/importEstimate',
        saveImportEstimates: 'estimates/import-estimates',
        ioIssuedDates: 'campaign/io/issued',
        estimateDistribution: 'estimates/distribution',
        downloadTemplate: 'aspose/downloadEstimateTemplate',
        estimateExport: 'aspose/exportEstimate'
    }
};
