<div class="import" *ngIf="directSubmit">
    <form [formGroup]="fileUploadForm">
        <div class="drop-zone" appDragDrop (fileDropped)="onFileDropped($event)">
            <input type="file" accept=".xlsx, .xls, .csv" #UploadFileInput id="UploadFileInput" [disabled]="loadingIcon" class="drop-zone__input" multiple (change)="onFileSelect($event)" />
            <span fxLayout="column" class="drop-zone__prompt" fxLayoutAlign="space-around center">
                <span class="import-icon"><fa-icon [icon]="faFileExcel"></fa-icon></span>
                <div class="import-text">
                    <span *ngIf="!wrongExcelFormat">Drag in Excel file to Upload</span>
                    <span class="xl-warning-icon" *ngIf="wrongExcelFormat">
                        <mat-icon class="warning-icon">warning</mat-icon>
                        <span>This file is not the correct template</span>
                    </span>
                </div>
                <span>
                    <button mat-raised-button class="primary-small-btn"> <label for="UploadFileInput">UPLOAD EXCEL DOCUMENT</label></button>
                </span>
            </span>
        </div>
    </form>
</div>
<div class="import" *ngIf="!directSubmit">
    <form [formGroup]="fileUploadForm">
        <div class="drop-zone" appDragDrop (fileDropped)="onFileDropped($event)" *ngIf="fileType === 'excel' && !showFileName">
            <input type="file" accept=".xlsx, .xls, .csv" #UploadFileInput id="UploadFileInput" [disabled]="loadingIcon" class="drop-zone__input" multiple (change)="onFileSelect($event)" />
            <span fxLayout="column" class="drop-zone__prompt" fxLayoutAlign="space-around center">
                <span class="import-icon"><fa-icon [icon]="faFileExcel"></fa-icon></span>
                <!-- <fa-icon class="import-icon" [icon]="faFileExcel"></fa-icon> -->
                <div class="import-text">
                    <span *ngIf="!wrongExcelFormat">Drag in Excel file to Upload</span>
                    <span class="xl-warning-icon" *ngIf="wrongExcelFormat">
                        <mat-icon class="warning-icon">warning</mat-icon>
                        <span>This file is not the correct template</span>
                    </span>
                </div>
                <span>
                    <button mat-raised-button class="primary-small-btn"> <label for="UploadFileInput">UPLOAD EXCEL DOCUMENT</label></button>
                </span>
            </span>
        </div>
        <div *ngIf="showFileName && !wrongExcelFormat" fxLayout="column" fxLayoutAlign="space-around center" class="choose-new">
            <span><fa-icon [icon]="faFileExcel"></fa-icon> {{fileInputLabel}} </span>
            <input type="file" accept=".xlsx, .xls, .csv" #UploadNewInput id="UploadNewInput" [disabled]="loadingIcon" class="drop-zone__input" multiple (change)="onFileSelect($event)" />
            <button mat-raised-button class="update-btn"><label for="UploadNewInput">CHOOSE DIFFERENT FILE</label></button>
        </div>
        <div class="drop-zone" appDragDrop (fileDropped)="onFileDropped($event)" *ngIf="fileType === 'pdf' && !showPdfFileName">
            <input type="file" accept="application/pdf" #UploadPdfInput id="UploadPdfInput" [disabled]="loadingIcon" class="drop-zone__input" multiple (change)="onFileSelect($event, fileType)" />
            <span fxLayout="column" fxLayoutAlign="space-around center">
                <span class="pdf-icon"><fa-icon [icon]="faFilePdf"></fa-icon></span>
                <div class="import-text">
                    <span *ngIf="!wrongPdfFormat">Drag in PDF documents to Upload</span>
                    <span class="xl-warning-icon" *ngIf="wrongPdfFormat">
                        <mat-icon class="warning-icon">warning</mat-icon>
                        <span>This file is not the correct template</span>
                    </span>
                </div>
                <span>
                    <button mat-raised-button class="primary-small-btn" *ngIf="fileType === 'pdf'"> <label for="UploadPdfInput">UPLOAD PDF DOCUMENT</label></button>
                </span>
            </span>
        </div>
        <div *ngIf="showPdfFileName && !wrongPdfFormat" fxLayout="column" fxLayoutAlign="space-around center" class="choose-new">
            <span><fa-icon [icon]="faFilePdf"></fa-icon> {{nameList.length}} PDF{{nameList.length > 1 ? 's' : ''}} Uploaded </span>
            <input type="file" accept="application/pdf" #UploadInput id="UploadInput" [disabled]="loadingIcon" class="drop-zone__input" multiple (change)="addFiles($event, fileType)" />
            <div fxLayout="row">
                <button mat-raised-button class="update-btn"><label for="UploadInput">ADD FILES</label></button>
                <button mat-raised-button class="update-btn" (click)="openFileList()" [disabled]="disableBtn">VIEW FILE LIST</button>
            </div>
        </div>
    </form>
</div>
<mat-spinner *ngIf="loadingIcon" class="loader-icon"></mat-spinner>
