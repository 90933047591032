import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginErrorComponent } from './media-management/login-error/login-error.component';


const routes: Routes = [
  // { path: '', loadChildren: () => import('./media-management/estimates/estimates.module').then(m => m.EstimatesModule)},
  { path: '', redirectTo: 'campaigns', pathMatch: 'full' },
  { path: 'campaigns' , loadChildren: () => import('./media-management/campaigns/campaigns.module').then(m => m.CampaignsModule)},
  { path: 'invoices' , loadChildren: () => import('./media-management/invoices/invoices.module').then(m => m.InvoicesModule)},
  { path: 'reports' , loadChildren: () => import('./media-management/reports/reports.module').then(m => m.ReportsModule)},
  { path: 'accruals' , loadChildren: () => import('./media-management/accruals/accruals.module').then(m => m.AccrualsModule)},
  { path: 'admin' , loadChildren: () => import('./media-management/admin/admin.module').then(m => m.AdminModule)},
  { path: 'estimates' , loadChildren: () => import('./media-management/campaigns/campaigns.module').then(m => m.CampaignsModule)},
  {path: 'error' , component: LoginErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class ModuleRouting { }
