import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonService } from '../services/common.service';
// import { IEstimatesDetail } from '../models/estimates.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CampaignsConstants } from '../media-management/campaigns/campaigns.constants';

@Injectable({
    providedIn: 'root'
})
export class IOService {
    ios = {
        "campaings": {
            "viewBy": "Q",
            "year": "",
            "ioList": [
                {
                    "showName": "Brooklyn Nine-Nine Season 7",
                    "showId": "",
                    "ioId": 10001,
                    "status": "draft",
                    "ioTotalCommitmentAmount": 150000,
                    "startDate": "02/04/2020",
                    "endDate": "03/31/2020",
                    "mediaType": "Television",
                    "mediaSubType": "Local Cable",
                    "seasonType": "Fall",
                    "accountNumber": 1122334455,
                    "comments": "Notes 1",
                    "ioDistribution": [
                        {
                            "ioDetailId": 1,
                            "startDate": "01/01/2020",
                            "endDate": "01/31/2020",
                            "commitmentAmount": 50000,
                            "wbselement": "GM2014701"
                        },
                        {
                            "ioDetailId": 2,
                            "startDate": "02/01/2020",
                            "endDate": "02/28/2020",
                            "commitmentAmount": 50000,
                            "wbselement": "GM2014702"
                        },
                        {
                            "ioDetailId": 3,
                            "startDate": "03/01/2020",
                            "endDate": "03/31/2020",
                            "commitmentAmount": 50000,
                            "wbselement": "GM2014702"
                        },
                    ]
                },
                {
                    "showName": "Americas Got Talent",
                    "showId": "",
                    "ioId": 10002,
                    "status": "draft",
                    "ioTotalCommitmentAmount": 180000,
                    "startDate": "02/04/2020",
                    "endDate": "03/31/2020",
                    "mediaType": "Television",
                    "mediaSubType": "Local Cable",
                    "seasonType": "Fall",
                    "accountNumber": 1122334455,
                    "comments": "Notes 2",
                    "ioDistribution": [
                        {
                            "ioDetailId": 4,
                            "startDate": "01/01/2020",
                            "endDate": "01/31/2020",
                            "commitmentAmount": 60000,
                            "wbselement": "GM2014801"
                        },
                        {
                            "ioDetailId": 5,
                            "startDate": "02/01/2020",
                            "endDate": "02/28/2020",
                            "commitmentAmount": 60000,
                            "wbselement": "GM2014802"
                        },
                        {
                            "ioDetailId": 6,
                            "startDate": "03/01/2020",
                            "endDate": "03/31/2020",
                            "commitmentAmount": 60000,
                            "wbselement": "GM2014802"
                        },
                    ]
                }
            ]
        }
    };

    masterDataCampaigns = {
        "campaignLOV": {
            "genre": [//List of genre,along with genre code.
                {
                    "genre": "Series",
                    "genreCode": 1247
                },
                {
                    "genre": "Series",
                    "genreCode": 1247
                }],
            "mediaType": [//List of Mediatypes - associated mediasub type, with company code & GlAccount#
                {
                    "mediaTypeName": "Television",
                    "mediaSubType": [
                        {
                            "mediaSubTypeId": 1,
                            "mediaSubTypeName": "Local Cable",
                            "glAccountNumber": 1234
                        },
                        {
                            "mediaSubTypeId": 2,
                            "mediaSubTypeName": "NewsPaper",
                            "glAccountNumber": 1332
                        }
                    ],
                },
                {
                    "mediaTypeName": "digital",
                    "mediaSubType": [
                        {
                            "mediaSubTypeId": 3,
                            "mediaSubTypeName": "Streaming Radio",
                            "glAccountNumber": 1234
                        },
                        {
                            "mediaSubTypeId": 4,
                            "mediaSubTypeName": "Podcast",
                            "glAccountNumber": 1332
                        }
                    ],
                }
            ]
        }
    };
    public stopProgressLoader   = new BehaviorSubject(false);

    constructor(private http: HttpClient, private cS: CommonService) {
    }

    fetchIOs(payLoad) {
        const url = environment.campaignBasePath + CampaignsConstants.apiUrl.getCampaignsIOs;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    fetchMasterDataCampaigns() {
        return this.masterDataCampaigns;
        // const url = environment.campaignBasePath + CampaignsConstants.apiUrl.masterDataCampaigns;
        // return this.cS.serviceRequestCommon('get', url);
    }

    saveAddIO(payLoad){
      const url = environment.campaignBasePath + CampaignsConstants.apiUrl.saveAddIOs;
      return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    saveFlights(payLoad) {
      const url = environment.campaignBasePath + CampaignsConstants.apiUrl.flightsSave;
      return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    deleteFlights(payLoad){
      const url = environment.campaignBasePath + CampaignsConstants.apiUrl.deleteFlight;
      return this.cS.serviceRequestCommon('put', url, '', payLoad);
    }

    issueSelectedIOs(payLoad) {
        const url = environment.campaignBasePath + CampaignsConstants.apiUrl.issueSelectedIOs;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    updateIO(payLoad) {
        const url = environment.campaignBasePath + CampaignsConstants.apiUrl.updateIO;
        return this.cS.serviceRequestCommon('put', url, '', payLoad);
    }

    deleteIO(payLoad){
      console.log(payLoad);
      const url = environment.campaignBasePath + CampaignsConstants.apiUrl.deleteIO;
      return this.cS.serviceRequestCommon('put', url, '', payLoad);
    }

    importIO(payLoad) {
      const url = environment.basePath + CampaignsConstants.apiUrl.importIO;
      return this.cS.post(url, payLoad);
    }

    importIOUpdate(payLoad) {
        const url = environment.basePath + CampaignsConstants.apiUrl.importIOUpdate;
        return this.cS.post(url, payLoad);
    }

    fetchIOTemplate() {
      const url = environment.basePath + CampaignsConstants.apiUrl.downloadTemplate;
      this.cS.downloadTemplate(url).subscribe((res: HttpResponse<any>) => {
        const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
        const a = document.createElement('a');
        const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
        // this.outputElem.nativeElement.click();
        this.stopProgressLoader.next(true);
      }, err => {
          this.stopProgressLoader.next(true);
      });
    }


    exportHandlerService(payLoad) {
        const url = environment.campaignBasePath + CampaignsConstants.apiUrl.export;
        this.cS.fileDownload(url, payLoad).subscribe((res: HttpResponse<any>) => {
          const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
          const a = document.createElement('a');
          const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          a.href = URL.createObjectURL(file);
          a.download = filename;
          a.click();
          // this.outputElem.nativeElement.click();
          this.stopProgressLoader.next(true);
        }, err => {
            this.stopProgressLoader.next(true);
        });
      }
}
