import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appValidationFormat]'
})
export class ValidationFormatterDirective {
    @Input('appValidationFormat') public validationFormat: string;
    private pattern: RegExp;
    private oldValue: string = '';
    private regexMap = { // add your own
        num4: /^\-?[0-9]{0,4}$/,
        num6: /^\-?[0-9]{0,6}$/,
        num2: /^\-?[0-9]{0,2}$/,
        num9: /^\-?[0-9]{0,9}$/,
        num5: /^\-?[0-9]{0,5}$/,
        num10: /^\-?[0-9]{0,10}$/,
        num2H: /^(2[0-3]|1[0-9]|[1-9])$/,
        num3MS: /^[1-5]?[0-9]$/,
        numshare: /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/,
        alpha10: /^[a-zA-Z0-9]{0,14}$/,
        num3LessThan100: /^[1-9][0-9]?$|^100$/,
        dec2: /^\d{0,15}([.][0-9]{0,2})?$/g , // /^\-?\d+\.\d{0,2}$/ ///^\-?\d(\.\d{0,2})?$/
        month: /^([1-9]|1[0-2])$/g,
        alphaNumMax10: /^[a-zA-Z0-9]{0,10}$/
    };
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];
    constructor(private el: ElementRef) {
    }
    @HostListener('keypress', ['$event']) public nInput(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        let regex = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/g);
        if (this.el.nativeElement.value.trim().length !== 0) {
            this.oldValue = this.el.nativeElement.value;
        }
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        this.pattern = this.regexMap[this.validationFormat];
        const current: string = this.el.nativeElement.value;
        const eleVal = this.el.nativeElement.value.substr(this.el.nativeElement.selectionStart, this.el.nativeElement.selectionEnd);
        const newEleValue = this.el.nativeElement.value.replace(eleVal, '');
        const nextValue: string = newEleValue.concat(event.key);
        if (nextValue.trim().length === 0) {
            event.preventDefault();
        }
        if (nextValue && !(String(nextValue).match(this.pattern))) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) public pInput(event: any) {
        const eleVal = this.el.nativeElement.value.substr(this.el.nativeElement.selectionStart, this.el.nativeElement.selectionEnd);
        const newEleValue = this.el.nativeElement.value.replace(eleVal, '');
        const pasteValue = event.clipboardData.getData('Text');
        let cleanString = '';
        if (this.validationFormat === 'alpha10') {
            cleanString = pasteValue.replace(/[\@\#\$\%\&\(\)\-\_\+\'\,\.\=\{\}\`\!\^\~\\\/\|\:\<\>\?\[\]\*\'\"\ ]/g, '');
            if (cleanString.length > 14){
                cleanString = cleanString.substring(0, 14);
            }
        } else {
            cleanString = pasteValue.replace(/[a-zA-Z\@\#\$\%\&\(\)\-\_\+\'\,\.\=\{\}\`\!\^\~\\\/\|\:\<\>\?\[\]\*\'\"\ ]/g, '');
        }
        event.clipboardData.setData('Text', cleanString);
        // this.el.nativeElement.value = '';
        cleanString = newEleValue + cleanString;
        if (this.validationFormat === 'num3LessThan100'){
            this.pattern = this.regexMap[this.validationFormat];
            if (pasteValue && !String(pasteValue).match(this.pattern)) {
                event.preventDefault();
            }
        }
        else {
            setTimeout(() => {
                this.el.nativeElement.value = cleanString;
                this.el.nativeElement.dispatchEvent(new Event('input'));
            });
            return true;
        }
    }
    @HostListener('keyup', ['$event']) public iInput(event: any) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current = event.target.value.trim();
        if (current.length === 0) {
            this.el.nativeElement.value = '';
            setTimeout(() => {
                if (event.key !== 'Control' && event.key !== 'x') {
                    this.el.nativeElement.value = current; // current.length === 0 ? this.oldValue : current;
                }
                this.el.nativeElement.dispatchEvent(new Event('input'));
            });
        }
        return true;
    }
}
